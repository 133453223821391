import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: nowrap;

  @media (min-width: 769px) {
    gap: 4rem;
  }

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;

    gap: 2rem;
  }
`;

export const ProductWrapper = styled.div`
  background-color: #f8f8f8;
  margin: 1rem 10% 3rem;

  .breadcrumb {
    font-size: 18px;
    color: ${(props) => (props.isDarkTheme ? props.primaryColor : props.auxColor)};
    padding: 15px 0;
    display: inline-block;
    text-decoration: none;
    font-weight: 600;
  }

  .row-whatsapp {
    margin-top: 20px;
    justify-content: center;
  }

  .image-placeholder {
    border: ${(props) => (!props.isDarkTheme ? '1px solid #ccc' : 'none')};
    background-color: ${(props) => props.primaryColor};
    color: ${(props) => props.auxColor}b2;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    width: 100%;
    margin-bottom: 0.5rem;
    padding: 1rem;
    text-align: center;
    font-size: 3rem;
    border-radius: 5px;
  }

  .product-image {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin: 0;
    aspect-ratio: 1;
    width: 40%;

    @media (max-width: 768px) {
      width: 70%;
    }

    @media (max-width: 576px) {
      width: 100%;
    }
  }

  .product-image img {
    width: 100% !important;
    height: 100% !important;
    aspect-ratio: 1 !important;
    object-fit: cover !important;
  }

  .swiper-pagination-bullet-active {
    background-color: #787878dd;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 1.5rem;
    color: #efefef;
  }

  .swiper-button-next {
    right: 1rem;
  }

  .swiper-button-prev {
    left: 1rem;
  }

  .text-error {
    color: #d9534f;
    font-size: 0.8rem;
  }

  h1 {
    font-size: 1rem;
    color: #3a3a3a;
    font-weight: 500;
  }

  h3 {
    font-size: 0.75rem;
    color: #999;
    margin-top: 0.25rem;
    font-weight: normal;
  }

  h4 {
    font-size: 24px;
    font-weight: 600;
    color: ${(props) => (props.isDarkTheme ? props.primaryColor : props.auxColor)};
    margin-top: 20px;
  }

  .buy-buttons {
    display: flex;
    gap: 2rem;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 1rem;
    }
  }

  .buy-button {
    padding: 15px 30px;
    margin-top: 50px;
    border-radius: 6px;
    display: inline-block;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
    background-color: ${(props) => props.primaryColor || '#787878'};
    color: ${(props) => props.auxColor || 'white'};
    border: none;
    font-size: 1rem;

    @media (max-width: 768px) {
      margin-top: 10px;
      text-align: center;
    }

    i {
      margin-right: 0.5rem;
    }
  }
`;

export const DescriptionWrapper = styled.div`
  background-color: #fff;
  border-radius: 10px;
  color: #3a3a3a;
  padding: 1.5rem 2rem;
  margin-top: 2rem;

  h2 {
    font-size: 18px;
    font-weight: 400;
    color: #3a3a3a;
    margin-bottom: 1.5rem;
  }
`;

export const PromotionalPriceView = styled.div`
  display: flex;
  flex-direction: column;

  span {
    display: flex;
    text-decoration: line-through;
    font-size: 0.8rem;
    color: #666;
    margin-top: 20px;
  }

  h4 {
    margin-top: 0px;
    display: flex;
    position: relative;
  }
`;

export const PromotionalTag = styled.div`
  position: absolute;
  color: #fff;
  background-color: red;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: normal;
  top: 25px;
  left: 15px;
  z-index: 9999;
`;

export const WhatsappLink = styled.a`
  display: block;
  margin-top: 20px;
  padding: 10px 0px;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => (props.isDarkTheme ? props.primaryColor : props.auxColor)};
  text-align: center;
`;
