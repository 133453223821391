import axios from 'axios';
import { toast } from 'react-toastify';

var axiosHeaders = {};

// if (localStorage.getItem('@VIDDYCATALOGO:Token')) {
//   axiosHeaders['Authorization'] = 'Bearer ' + localStorage.getItem('@VIDDYCATALOGO:Token');
// }

if (localStorage.getItem('@VIDDYCATALOGO:X-Server')) {
  axiosHeaders['X-Server'] = localStorage.getItem('@VIDDYCATALOGO:X-Server');
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // eslint-disable-line
  headers: axiosHeaders,
});

api.CancelToken = axios.CancelToken;
api.isCancel = axios.isCancel;

// let isRefreshing = false;
// let failedQueue = [];

// const processQueue = (error, token = null) => {
//   failedQueue.forEach((prom) => {
//     if (error) {
//       prom.reject(error);
//     } else {
//       prom.resolve(token);
//     }
//   });

//   failedQueue = [];
// };

api.interceptors.response.use(
  (response) => response,
  async (err) => {
    if (!err.response) {
      toast.error('Não foi possível comunicar com o servidor');
    }

    // const originalRequest = err.config;

    // const needsToRefresh =
    //   err.response &&
    //   err.response.status === 401 &&
    //   typeof err?.response?.data?.message === 'string' &&
    //   err?.response?.data?.message === 'Token has expired';

    // if (needsToRefresh) {
    //   if (isRefreshing) {
    //     return new Promise((resolve, reject) => {
    //       failedQueue.push({ resolve, reject });
    //     })
    //       .then((token) => {
    //         originalRequest.headers.authorization = `Bearer ${token}`;
    //         return api(originalRequest);
    //       })
    //       .catch((error) => error);
    //   }

    //   isRefreshing = true;

    //   // eslint-disable-next-line no-async-promise-executor
    //   return new Promise(async (resolve, reject) => {
    //     try {
    //       const { data } = await api.get('/auth/refresh-token');

    //       localStorage.setItem('@VIDDYCATALOGO:Token', data.token);
    //       localStorage.setItem('@VIDDYCATALOGO:X-Server', data.server);

    //       api.defaults.headers.authorization = `Bearer ${data.token}`;
    //       api.defaults.headers['X-Server'] = data.server;

    //       originalRequest.headers.authorization = api.defaults.headers.authorization;

    //       processQueue(null, data.token);
    //       resolve(api(originalRequest));
    //     } catch (error) {
    //       processQueue(error, null);
    //       window.location.reload();
    //       reject(error);
    //     } finally {
    //       isRefreshing = false;
    //     }
    //   });
    // }

    return Promise.reject(err);
  }
);

export default api;
