import api from '../api';

export async function getCustomerSales(token, slug) {
  try {
    const { data } = await api.get('store/' + slug + '/sales', {
      headers: { Authorization: 'Bearer ' + token },
    });
    return {
      message: 'Compras de cliente resgatados com sucesso!',
      success: true,
      data,
    };
  } catch (error) {
    return {
      message: 'Erro no resgate de compras de cliente. Tente mais tarde.',
      success: false,
    };
  }
}

export async function showCustomerSale(token, id, slug) {
  try {
    const { data } = await api.get('store/' + slug + '/sales/' + id, {
      headers: { Authorization: 'Bearer ' + token },
    });
    return {
      message: 'Compra de cliente resgatada com sucesso!',
      success: true,
      data,
    };
  } catch (error) {
    return {
      message: 'Erro no resgate de compra de cliente. Tente mais tarde.',
      success: false,
    };
  }
}
