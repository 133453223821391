import React, { useContext, useEffect, useState } from 'react';
import {
  SaleContainer,
  SaleContainerHeader,
  SaleContentTitle,
  SaleContentOrderNumber,
  SaleContactButton,
  SaleOrderDetails,
  SaleItemList,
  SaleItem,
  SaleItemImage,
  SaleItemInfo,
  SaleItemName,
  SaleItemPrice,
  SaleSummary,
  SaleSummaryRow,
  SaleSummaryLabel,
  SaleSummaryValue,
  SaleTotal,
  SaleDetailsSection,
  SaleDetailsRow,
  SaleDetailsLabel,
  SaleDetailsValue,
  ConfirmationStep,
  PaymentDescription,
  ButtonRounded,
} from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import useColors from '../../hooks/useColors';
import CheckoutContext from '../../contexts/CheckoutContext';
import { numberToReal } from '../../utils/helpers';
import CustomerHeader from '../../components/CustomerHeader';
import SiteConfigContext from '../../contexts/SiteConfigContext';
import LoadingSpinner from '../../components/Loading/LoadingSpinner';
import AuthContext from '../../contexts/AuthContext';
import { showCustomerSale } from '../../services/Sales';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import mercadopagopng from '../../resources/img/mercadopago.png';
import { toast } from 'react-toastify';

const SaleDetail = () => {
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { primaryColor, auxColor, isDarkTheme } = useColors();
  const { siteConfig } = useContext(SiteConfigContext);
  const { authCustomer, authCustomerToken } = useContext(AuthContext);
  const [showLoading, setShowLoading] = useState(true);
  const [orderData, setOrderData] = useState(null);

  useEffect(() => {
    const loadSale = async () => {
      setShowLoading(true);
      const { data, success } = await showCustomerSale(
        authCustomerToken,
        orderId,
        siteConfig.store.slug
      );
      if (success) {
        setOrderData(data);
      } else {
        toast.error('Houve um erro ao carregar os seu pedido.');
      }
      setShowLoading(false);
    };

    if (authCustomerToken) {
      loadSale();
    } else {
      navigate('/');
    }
  }, [authCustomerToken, navigate, orderId, siteConfig.store.slug]);

  const redirectToSales = () => {
    window.location.href = '/meus-pedidos';
  };

  return (
    <>
      <Header />
      <CustomerHeader primaryColor={primaryColor} page="sales" />
      <ConfirmationStep primaryColor={primaryColor} auxColor={auxColor}>
        {!showLoading && (
          <SaleContainer>
            <SaleContainerHeader>
              <div>
                <SaleContentTitle primaryColor={primaryColor}>
                  Pedido {'#' + orderData.sale.id}
                </SaleContentTitle>
              </div>
            </SaleContainerHeader>

            <SaleOrderDetails>
              <SaleSummary>
                <SaleItemList>
                  {orderData.sale.products?.map((product) => (
                    <SaleItem key={product.id} primaryColor={primaryColor}>
                      {product.user_product?.image ? (
                        <SaleItemImage>
                          <img
                            src={
                              product.user_product?.image
                                ? product.user_product.image
                                : 'https://placehold.co/600x400'
                            }
                            alt="Camiseta Skull"
                            width={60}
                            height={60}
                          />
                        </SaleItemImage>
                      ) : (
                        <div className="product-image">
                          <i className="fa-solid fa-camera"></i>
                        </div>
                      )}

                      <SaleItemInfo>
                        <SaleItemName>{product.description}</SaleItemName>
                        <SaleItemPrice>
                          {product.promotional_price
                            ? numberToReal(
                                (product.promotional_price * product.quantity).toFixed(2)
                              )
                            : numberToReal((product.sell_price * product.quantity).toFixed(2))}
                        </SaleItemPrice>
                      </SaleItemInfo>
                    </SaleItem>
                  ))}
                </SaleItemList>
                {orderData.sale?.order_type === 'delivery' && (
                  <>
                    <SaleSummaryRow>
                      <SaleSummaryLabel>Subtotal</SaleSummaryLabel>
                      <SaleSummaryValue>
                        {numberToReal(parseFloat(orderData.sale.value).toFixed(2))}
                      </SaleSummaryValue>
                    </SaleSummaryRow>
                    <SaleSummaryRow>
                      <SaleSummaryLabel>
                        Entrega ({orderData.sale.delivery_type.name})
                      </SaleSummaryLabel>
                      <SaleSummaryValue>
                        {numberToReal(parseFloat(orderData.sale.delivery_type.value).toFixed(2))}
                      </SaleSummaryValue>
                    </SaleSummaryRow>
                  </>
                )}
                <SaleTotal>
                  <SaleSummaryLabel>TOTAL:</SaleSummaryLabel>
                  <SaleSummaryValue>
                    {numberToReal(orderData.sale.full_value.toFixed(2))}
                  </SaleSummaryValue>
                </SaleTotal>
              </SaleSummary>
              <SaleDetailsSection>
                <SaleDetailsRow>
                  <SaleDetailsLabel primaryColor={primaryColor}>
                    MÉTODO DE PAGAMENTO
                  </SaleDetailsLabel>
                  <SaleDetailsValue>{orderData.sale.payment_option?.name}</SaleDetailsValue>
                  {orderData.sale.payment_option?.description && (
                    <PaymentDescription>
                      {orderData.sale.payment_option.description}
                    </PaymentDescription>
                  )}
                </SaleDetailsRow>
                {orderData.sale.order_type === 'pickup' && (
                  <SaleDetailsRow>
                    <SaleDetailsLabel primaryColor={primaryColor}>RETIRADA</SaleDetailsLabel>
                    <SaleDetailsValue>
                      {siteConfig.store.address?.address} {siteConfig.store.address?.address_number}
                      <br />
                      {siteConfig.store.address?.address_complement}
                      <br />
                      {siteConfig.store.address?.city}
                      <br />
                      {siteConfig.store.address?.state}
                    </SaleDetailsValue>
                  </SaleDetailsRow>
                )}
                {orderData.sale.order_type === 'delivery' && (
                  <SaleDetailsRow>
                    <SaleDetailsLabel primaryColor={primaryColor}>ENTREGA</SaleDetailsLabel>
                    <SaleDetailsValue>
                      {orderData.sale.address?.address} {orderData.sale.address?.address_number}
                      <br />
                      {orderData.sale.address?.address_complement}
                      <br />
                      {orderData.sale.address?.city}
                      <br />
                      {orderData.sale.address?.state}
                    </SaleDetailsValue>
                  </SaleDetailsRow>
                )}
                <SaleDetailsRow>
                  <SaleDetailsLabel primaryColor={primaryColor}>IDENTIFICAÇÃO</SaleDetailsLabel>
                  <SaleDetailsValue>
                    {orderData.sale.customer?.name} - {orderData.sale.customer?.email}
                  </SaleDetailsValue>
                </SaleDetailsRow>
                {orderData.sale.annotation && (
                  <SaleDetailsRow>
                    <SaleDetailsLabel primaryColor={primaryColor}>OBSERVAÇÃO</SaleDetailsLabel>
                    <SaleDetailsValue>{orderData.sale.annotation}</SaleDetailsValue>
                  </SaleDetailsRow>
                )}
              </SaleDetailsSection>
            </SaleOrderDetails>
            {authCustomer && (
              <ButtonRounded
                onClick={redirectToSales}
                auxColor={primaryColor}
                primaryColor={auxColor}
                isDarkTheme={isDarkTheme}
              >
                Voltar
              </ButtonRounded>
            )}
          </SaleContainer>
        )}
        {showLoading && (
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignSelf: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '10%',
            }}
          >
            <LoadingSpinner />
          </div>
        )}
      </ConfirmationStep>
      <Footer />
    </>
  );
};

export default SaleDetail;
