import styled from 'styled-components';

export const OrderListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 10px;
`;

export const OrderItem = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #fff;
  padding: 20px 15px;
  border-radius: 8px;
  box-shadow: 1px 2px 10px #ddd;
  gap: 10px;
`;

export const OrderData = styled.div`
  flex: 1;
`;

export const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 5px;
  flex: 3;

  h3 {
    font-size: 14px;
    color: #000;
  }

  p {
    color: #000;
  }
`;

export const OrderTotal = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;

  span {
    font-size: 14px;
  }

  h3 {
    color: ${(props) => (props.primaryColor === '#FFFFFF' ? props.primaryColor : 'black')};
  }
`;

export const ProductDetails = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;

  img {
    width: 60px;
    height: 60px;
    margin-right: 10px;
  }

  div {
    display: flex;
    flex-direction: column;
  }

  .product-image {
    border: none;
    aspect-ratio: 1;
    width: 4rem;
    height: 4rem;
    @media (max-width: 768px) {
      width: 30cqw;
      height: 30cqw;
    }
    border-radius: 5px;
    font-size: 1.5rem;
  }

  div.product-image {
    padding: 1rem;
    background-color: ${(props) => props.primaryColor};
    color: #faab2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-align: center;
    margin-right: 10px;
  }
`;
