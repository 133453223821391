import React from 'react';
import {
  OrderItem,
  OrderData,
  OrderTotal,
  OrderListContainer,
  ProductDetails,
  ProductList,
} from './styles';
import { NavLink } from 'react-router-dom';
import { numberToReal } from '../../utils/helpers';
import useColors from '../../hooks/useColors';
import Helpers from '../../utils/helpers';

const SalesList = ({ sales }) => {
  const { primaryColor, auxColor, isDarkTheme } = useColors();

  return (
    <OrderListContainer>
      {sales.map((order) => (
        <OrderItem key={order.id}>
          <OrderData>
            <NavLink
              style={{ textDecoration: 'none', color: 'black' }}
              to={'/meus-pedidos/' + order.id}
            >
              <h3># {order.id}</h3>
            </NavLink>

            <p>
              <b>{Helpers.formatToBrlDate(order.date)}</b>
            </p>
            <p style={{ fontSize: '14px' }}>Pagamento: {order.payment_option?.name}</p>
          </OrderData>
          <ProductList>
            {order.products.map((product) => (
              <ProductDetails key={product.id} primaryColor={primaryColor}>
                {product?.user_product?.image ? (
                  <img src={product.user_product.image} alt={product.description} />
                ) : (
                  <div className="product-image">
                    <i className="fa-solid fa-camera"></i>
                  </div>
                )}
                <NavLink
                  target="_blank"
                  to={'/produtos/' + product.id}
                  style={{ textDecoration: 'none' }}
                >
                  <h3>{product.description}</h3>
                  <p style={{ fontSize: '14px' }}>ID do Produto: {product.user_product_id}</p>
                </NavLink>
              </ProductDetails>
            ))}
          </ProductList>
          <OrderTotal>
            <p>
              <b>{numberToReal(order.full_value.toFixed(2))}</b>
            </p>
            <span>{order.status ? 'Confirmado' : 'Pendente'}</span>
            <NavLink
              style={{ textDecoration: 'none', color: primaryColor }}
              to={'/meus-pedidos/' + order.uuid}
            >
              <h3>Ver detalhes</h3>
            </NavLink>
          </OrderTotal>
        </OrderItem>
      ))}
    </OrderListContainer>
  );
};

export default SalesList;
