import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ProductList,
  P,
  FormCol,
  ButtonRounded,
  FormRow,
  ConfirmModal,
  IconButton,
  IconButtonStyle,
} from './styles';
import { numberToReal } from '../../utils/helpers';
import CheckoutContext from '../../contexts/CheckoutContext';
import useColors from '../../hooks/useColors';
import { ProductCard } from './ProductCard';
import { NavPills } from './NavPills';
import { NotesModal } from './NotesModal';

export const CheckoutSummary = ({ maxProgress, progress, setProgress }) => {
  const navigate = useNavigate();
  const { primaryColor, auxColor, isDarkTheme } = useColors();
  const { checkoutProducts, setCheckoutProducts, checkoutNote, setCheckoutNote } =
    useContext(CheckoutContext);
  const [showClearCartModal, setShowClearCartModal] = useState(false);
  const [showCartNotesModal, setShowCartNotesModal] = useState(false);

  const checkoutSubtotal = checkoutProducts.reduce(
    (acc, product) => acc + product.price * product.quantity,
    0
  );

  // onClick={() => setCheckoutProducts([...checkoutProducts, { id: parseInt(Math.random() * 10000), price: parseInt(Math.random() * 100), quantity: parseInt(Math.random() * 10), name: 'hello world' }])}

  return (
    <>
      <ProductList primaryColor={primaryColor}>
        {checkoutProducts.length > 0 && (
          <>
            <NavPills maxProgress={maxProgress} progress={progress} setProgress={setProgress} />
            <ul className="list-products">
              <li className="list-headers">
                <p style={{ marginLeft: '-3rem' }}>{checkoutProducts.length} itens</p>
                <p style={{ marginLeft: '1rem' }}>Valor Unitário</p>
                <p>Quantidade</p>
                <p>Total</p>
              </li>
              {checkoutProducts
                .sort((fproduct, gproduct) => fproduct.id > gproduct.id)
                .map((product) => (
                  <li key={product.id + product.quantity}>
                    <ProductCard product={product} progress={progress} />
                  </li>
                ))}
            </ul>
          </>
        )}

        {checkoutProducts.length === 0 && (
          <div className="empty-cart">
            <h4>Seu carrinho está vazio! Adicione algum produto.</h4>
          </div>
        )}

        {checkoutProducts.length > 0 && (
          <div className="product-list-total">
            <P style={{ display: 'flex', justifyContent: 'space-between' }}>
              {!checkoutNote || checkoutNote.length === 0 ? (
                <IconButton onClick={() => setShowCartNotesModal(true)}>
                  Adicionar Observação
                </IconButton>
              ) : (
                <>
                  <IconButtonStyle>
                    <span>{checkoutNote}</span>
                    <button
                      style={{ border: 'none', cursor: 'pointer', backgroundColor: 'transparent' }}
                      onClick={(e) => {
                        setShowCartNotesModal(true);
                      }}
                    >
                      <img
                        src="https://api.iconify.design/material-symbols:ink-pen-outline.svg"
                        alt="Editar anotação"
                        width="20"
                        height="20"
                      />
                    </button>
                    <button
                      style={{ border: 'none', cursor: 'pointer', backgroundColor: 'transparent' }}
                      onClick={(e) => {
                        e.preventDefault();
                        setCheckoutNote('');
                      }}
                    >
                      <img
                        src="https://api.iconify.design/material-symbols:delete-outline.svg"
                        alt="Limpar carrinho"
                        width="20"
                        height="20"
                      />
                    </button>
                  </IconButtonStyle>
                </>
              )}
              <IconButton onClick={() => setShowClearCartModal(true)}>
                <img
                  src="https://api.iconify.design/material-symbols:delete-outline.svg"
                  alt="Limpar carrinho"
                  width="20"
                  height="20"
                />
                <span>Limpar Carrinho</span>
              </IconButton>
            </P>
            <P style={{ fontSize: '1.1rem', marginTop: '10px', marginBottom: '10px' }}>
              Valor Total: {numberToReal(checkoutSubtotal.toFixed(2))}
            </P>
          </div>
        )}

        <FormRow
          style={{
            marginTop: 'auto',
            justifyContent: checkoutProducts.length > 0 ? 'end' : 'center',
          }}
        >
          <FormCol>
            <ButtonRounded
              onClick={() => navigate('/')}
              auxColor={auxColor}
              primaryColor={primaryColor}
              isDarkTheme={isDarkTheme}
            >
              {checkoutProducts.length === 0 ? 'Adicionar produtos' : 'Continuar comprando'}
            </ButtonRounded>
          </FormCol>
          {checkoutProducts.length > 0 && (
            <FormCol>
              <ButtonRounded
                onClick={() => setProgress(2)}
                auxColor={auxColor}
                primaryColor={primaryColor}
                isDarkTheme={isDarkTheme}
              >
                Confirmar
              </ButtonRounded>
            </FormCol>
          )}
        </FormRow>
      </ProductList>
      <ConfirmModal isOpen={showClearCartModal}>
        <div className="modal-header">
          <h3>Limpar carrinho</h3>
          <button className="close-button" onClick={() => setShowClearCartModal(false)}>
            ×
          </button>
        </div>
        <div className="modal-footer">
          <button
            onClick={() => setShowClearCartModal(false)}
            style={{
              backgroundColor: primaryColor,
              color: auxColor,
              padding: '5px',
            }}
          >
            Cancelar
          </button>
          <button
            onClick={() => {
              setCheckoutProducts([]);
              navigate('/');
            }}
            style={{ backgroundColor: auxColor, padding: '5px', color: primaryColor }}
          >
            Confirmar
          </button>
        </div>
      </ConfirmModal>
      <NotesModal show={showCartNotesModal} setShow={setShowCartNotesModal} />
    </>
  );
};
