import styled from 'styled-components';

export const Footer = styled.footer`
  margin-top: auto;
  width: 100%;

  background-color: ${(props) => props.primaryColor};

  padding: 3rem 10% 2.5rem;

  font-size: 0.85rem;

  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;

    align-items: center;

    & > p {
      margin: 2rem 0 3.5rem;
    }
  }
`;

export const P = styled.p`
  color: ${(props) => props.auxColor};
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

export const Column = styled(Row)`
  flex-direction: column;
`;

export const FloatingButton = styled.a`
  height: 60px;
  width: 60px;
  overflow: hidden;

  position: fixed;
  right: 40px;
  bottom: 70px;

  background-color: #fff;
  font-size: 30px;
  text-align: center;
  text-decoration: none;
  border: 1px solid #34c38f;
  border-radius: 50px;
  box-shadow: 2px 2px 3px #999;

  opacity: 0.5;
  transition: all 0.5s ease;

  &:hover {
    width: 210px;
    opacity: 1;
  }

  p {
    color: black;
    font-size: 14px;
    font-weight: 300;
    text-align: left;

    padding: 0.5rem 1rem 0.5rem 1.5rem;
  }

  i {
    position: fixed;
    bottom: 70px;
    right: 40px;

    padding-top: 0.8rem;

    width: 60px;
    height: 60px;

    background-color: #34c38f;
    color: #fff;

    border-radius: 50px;
    box-shadow: 2px 2px 3px #999;

    font-size: 30px;
  }
`;
