import { ErrorContainer } from './styles';

function Error() {
  return (
    <>
      <ErrorContainer>
        <p>Erro, catálogo não encontrado.</p>
      </ErrorContainer>
    </>
  );
}

export default Error;
