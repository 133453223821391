import axios from 'axios';

const baseURL = 'https://viacep.com.br/ws/';

const baseURLCities = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados/';

const baseURLUfs = 'https://servicodados.ibge.gov.br/api/v1/localidades/estados';

export const getAddressDataByCep = async (cep) => {
  const api = axios.create({ baseURL });

  const { data } = await api.get(`${cep}/json/`);
  return data;
};

export const getCitiesDataByUF = async (uf) => {
  const api = axios.create();

  const { data } = await api.get(`${baseURLCities}${uf}/distritos`);

  return data.map((register) => {
    return { id: register.id, name: register.nome };
  });
};

export const getUFData = async () => {
  const api = axios.create();

  const { data } = await api.get(`${baseURLUfs}`);

  return data.map((register) => {
    return { id: register.sigla, name: register.nome };
  });
};
