import { useState, useContext, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import useColors from '../../hooks/useColors';
import {
  ProfileCard,
  FormRow,
  FormCol,
  FormTitle,
  FormLabel,
  FormInput,
  FormErrorSpan,
  ProfileContactForm,
  ButtonRounded,
  ProfileContactLoading,
} from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import CustomerHeader from '../../components/CustomerHeader';
import LoadingSpinner from '../../components/Loading/LoadingSpinner';
import AuthContext from '../../contexts/AuthContext';
import { getCustomerSales } from '../../services/Sales';
import SiteConfigContext from '../../contexts/SiteConfigContext';
import SalesList from '../../components/SalesList';
import Pagination from '../../components/Pagination';

const ProfilePage = () => {
  const formRef = useRef(null);
  const { siteConfig } = useContext(SiteConfigContext);
  const { primaryColor } = useColors();
  const { authCustomerToken } = useContext(AuthContext);
  const [showLoading, setShowLoading] = useState(true);
  const [sales, setSales] = useState(null);
  const [salesFiltered, setSalesFiltered] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 12;
  const navigate = useNavigate();

  useEffect(() => {
    const loadSales = async () => {
      setShowLoading(true);
      const { data, success } = await getCustomerSales(authCustomerToken, siteConfig.store.slug);
      if (success) {
        setSales(data.sales);
        setTotalPages(Math.ceil(data.sales.length / itemsPerPage));
      } else {
        toast.error('Houve um erro ao carregar os seus pedidos.');
      }
      setShowLoading(false);
    };

    if (authCustomerToken) {
      loadSales();
    } else {
      navigate('/');
    }
  }, [authCustomerToken, navigate, siteConfig.store.slug]);

  useEffect(() => {
    const filterSales = () => {
      if (sales && sales.length) {
        const startIndex = (page - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;

        const filteredSales = sales.slice(startIndex, endIndex);
        setSalesFiltered(filteredSales);
      }
    };
    filterSales();
  }, [page, sales]);

  return (
    <>
      <Header />
      <CustomerHeader primaryColor={primaryColor} page="sales" />
      <ProfileCard primaryColor={primaryColor}>
        <ProfileContactForm ref={formRef}>
          <FormTitle>Meus Pedidos</FormTitle>
          {!showLoading && salesFiltered && salesFiltered.length > 0 && (
            <>
              <SalesList sales={salesFiltered} />
            </>
          )}
          {!showLoading && sales && sales.length === 0 && (
            <div style={{ marginTop: '10px' }}>
              <b>Você não possui pedidos ainda. Realize sua primeira compra!</b>
            </div>
          )}
          {showLoading && (
            <ProfileContactLoading>
              <LoadingSpinner color={primaryColor} />
            </ProfileContactLoading>
          )}
          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
            {!showLoading && sales && sales.length > 0 && totalPages > 1 && (
              <Pagination page={page} setPage={setPage} lastPage={totalPages} />
            )}
          </div>
        </ProfileContactForm>
      </ProfileCard>
      <Footer />
    </>
  );
};

export default ProfilePage;
