import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Modal from 'styled-react-modal';

// export const Header = styled.header`
//   background-color: ${(props) => props.primaryColor};
//   color: ${(props) => props.auxColor};

//   display: flex;
//   justify-content: space-between;

//   padding: 1.5rem 10% 2rem;

//   /* div {
//     position: relative;
//     margin-left: auto;
//     background-color: ${(props) => props.primaryColor};

//     padding: 0.4rem;
//     margin-top: 1rem;
//     border-radius: 999px;

//     span {
//       position: absolute;
//       display: grid;
//       place-items: center;
//       right: 0.4rem;
//       top: 0.2rem;
//       background-color: #b3b6b9bb;
//       border-radius: 9999px;
//       font-size: 0.8rem;
//       width: 1.2rem;
//       padding: 0;
//       color: ${(props) => props.auxColor};
//     }
//   } */

//   /* a { */
//   /* display: grid; */
//   /* place-items: center; */
//   /* padding: 0.5rem; */
//   /* } */
// `;

export const Cart = styled.div`
  padding: 0.5rem !important;
  align-self: center;
  margin-top: 0 !important;
  text-align: center;
  position: relative;
`;

export const CartItens = styled.span`
  position: absolute;
  display: grid;
  place-items: center;
  right: -0.5rem;
  top: -0.5rem;
  background-color: #b3b6b9bb;
  border-radius: 9999px;
  font-size: 0.8rem;
  width: 1.2rem;
  padding: 0;
  color: ${(props) => props.auxColor};
`;

export const Login = styled.div``;

export const Img = styled.img`
  max-width: 100px;

  @media (max-width: 768px) {
    max-width: 60%;
  }

  padding: 0.5rem;
`;

// Estilizando o header com Flexbox
export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.primaryColor};
  //padding: 10px 60px; /* Espaçamento interno do header */
  padding: 10px calc(10% - 1rem);
`;

// Estilizando a logo da loja
export const Logo = styled.div`
  display: flex;
  align-items: center;
  flex: 1; /* Permite que a logo ocupe espaço disponível */
  justify-content: flex-start;
`;

// Estilizando os ícones e textos de autenticação e carrinho
export const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1; /* Permite que o item ocupe espaço disponível */
  flex-direction: row;
  gap: 16px;
`;

// Estilizando os links do header
export const HeaderLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  font-size: 16px;
  position: relative;
`;

export const HeaderIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px; /* Espaçamento entre o ícone e o texto */
`;

export const ConfirmLogoutModal = Modal.styled`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 0 solid #f6f6f6;
  border-radius: .25rem;
  width: 80%;
  @media (min-width: 768px) {
    width: 30%;
  }

  .modal-header {
    align-items: flex-start;
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
    display: flex;
    justify-content: space-between;
    padding: 1rem;

    .close-button {
      margin: -1rem -1rem -1rem auto;
      padding: 0.75rem 1rem;
      background: none;
      border: none;
      cursor: pointer;
      font-size: 1.5rem;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: end;
    gap: 1rem;
    padding: 1rem;
    button {
      width: 30%;
      padding: 0.25rem 1rem;
      font-size: 1rem;
      border: none;
      color: white;
      cursor: pointer;
      @media (max-width: 992px) {
        width: calc(50% - 0.5rem);
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 0.5rem;
      button {
        width: 100%;
      }
    }
  }
`;
