import styled from 'styled-components';

export const CheckoutCard = styled.div`
  padding: 0 9%;
  position: relative;
  /* grid-template-rows: 1fr 8fr; */

  @media (max-width: 992px) {
    display: block;
  }

  min-height: 80cqh;
  /*
        height: 100%;
        max-height: 100vh;
        padding: 2rem 10vw;
        border-radius: 10px;
    */

  overflow: hidden;

  @media (max-width: 576px) {
    overflow: scroll;
    position: block;
  }
`;
