import React, { useContext, useEffect, useState } from 'react';
import {
  FinishCartContainer,
  FinishCartContainerHeader,
  FinishCartContentTitle,
  FinishCartContentOrderNumber,
  FinishCartContactButton,
  FinishCartOrderDetails,
  FinishCartItemList,
  FinishCartItem,
  FinishCartItemImage,
  FinishCartItemInfo,
  FinishCartItemName,
  FinishCartItemPrice,
  FinishCartSummary,
  FinishCartSummaryRow,
  FinishCartSummaryLabel,
  FinishCartSummaryValue,
  FinishCartTotal,
  FinishCartDetailsSection,
  FinishCartDetailsRow,
  FinishCartDetailsLabel,
  FinishCartDetailsValue,
  ConfirmationStep,
  PaymentDescription,
  FormCol,
  FormInput,
  FormRow,
  P,
  ProcessedByAdvice,
  ButtonRounded,
} from './styles';
/* import { ProductCard } from './ProductCard';
 */
import { useNavigate } from 'react-router-dom';
import useColors from '../../hooks/useColors';
import CheckoutContext from '../../contexts/CheckoutContext';
import { numberToReal } from '../../utils/helpers';
import { finishCart } from '../../services/Cart';
import SiteConfigContext from '../../contexts/SiteConfigContext';
import LoadingSpinner from '../Loading/LoadingSpinner';
import AuthContext from '../../contexts/AuthContext';
import mercadopagopng from '../../resources/img/mercadopago.png';
import { toast } from 'react-toastify';
import { NotesModal } from './NotesModal';

export const FinishStep = ({ orderData, formsState }) => {
  const navigate = useNavigate();
  const { primaryColor, auxColor, isDarkTheme } = useColors();
  const { siteConfig } = useContext(SiteConfigContext);
  const { checkoutProducts, checkoutNote } = useContext(CheckoutContext);
  const { authCustomer } = useContext(AuthContext);
  const [showLoading, setShowLoading] = useState(false);

  const handleCallStore = (e) => {
    const url =
      siteConfig?.configs?.whatsapp +
      '&text=Olá! Estou com uma dúvida sobre o pedido Nº' +
      orderData.sale.id +
      ', pode me ajudar?';
    window.open(url);
  };

  const redirectToStore = () => {
    window.location.href = '/';
  };

  const redirectToSales = () => {
    window.location.href = '/meus-pedidos';
  };

  return (
    <ConfirmationStep primaryColor={primaryColor} auxColor={auxColor}>
      {!showLoading && (
        <FinishCartContainer>
          <FinishCartContainerHeader>
            <div>
              <FinishCartContentTitle primaryColor={primaryColor}>
                Pedido realizado com sucesso!
              </FinishCartContentTitle>
              <FinishCartContentOrderNumber>
                Compra #{orderData.sale.id}
              </FinishCartContentOrderNumber>
            </div>
            {siteConfig?.configs?.whatsapp && (
              <FinishCartContactButton primaryColor={primaryColor} onClick={handleCallStore}>
                Fale com a Loja
              </FinishCartContactButton>
            )}
          </FinishCartContainerHeader>
          <FinishCartOrderDetails>
            <FinishCartSummary>
              <FinishCartItemList>
                {orderData.sale.products?.map((product) => (
                  <FinishCartItem key={product.id} primaryColor={primaryColor}>
                    {product?.user_product?.image ? (
                      <FinishCartItemImage>
                        <img
                          src={product.user_product?.image}
                          alt="Imagem Produto"
                          width={60}
                          height={60}
                        />
                      </FinishCartItemImage>
                    ) : (
                      <div className="product-image">
                        <i className="fa-solid fa-camera"></i>
                      </div>
                    )}
                    <FinishCartItemInfo>
                      <FinishCartItemName>{product.description}</FinishCartItemName>
                      <FinishCartItemPrice>
                        {product.promotional_price
                          ? numberToReal((product.promotional_price * product.quantity).toFixed(2))
                          : numberToReal((product.sell_price * product.quantity).toFixed(2))}
                      </FinishCartItemPrice>
                    </FinishCartItemInfo>
                  </FinishCartItem>
                ))}
              </FinishCartItemList>
              {orderData.sale?.order_type === 'delivery' && (
                <>
                  <FinishCartSummaryRow>
                    <FinishCartSummaryLabel>Subtotal</FinishCartSummaryLabel>
                    <FinishCartSummaryValue>
                      {numberToReal(parseFloat(orderData.sale.value).toFixed(2))}
                    </FinishCartSummaryValue>
                  </FinishCartSummaryRow>
                  <FinishCartSummaryRow>
                    <FinishCartSummaryLabel>Entrega</FinishCartSummaryLabel>
                    <FinishCartSummaryValue>
                      {numberToReal(parseFloat(orderData.sale?.delivery_type_value).toFixed(2))}
                    </FinishCartSummaryValue>
                  </FinishCartSummaryRow>
                </>
              )}
              <FinishCartTotal>
                <FinishCartSummaryLabel>TOTAL:</FinishCartSummaryLabel>
                <FinishCartSummaryValue>
                  {numberToReal(orderData.sale.full_value.toFixed(2))}
                </FinishCartSummaryValue>
              </FinishCartTotal>
            </FinishCartSummary>
            <FinishCartDetailsSection>
              <FinishCartDetailsRow>
                <FinishCartDetailsLabel primaryColor={primaryColor}>
                  MÉTODO DE PAGAMENTO
                </FinishCartDetailsLabel>
                <FinishCartDetailsValue>{orderData.sale.payment?.name}</FinishCartDetailsValue>
                {orderData.sale.payment?.description && (
                  <PaymentDescription>{orderData.sale.payment.description}</PaymentDescription>
                )}
              </FinishCartDetailsRow>
              {orderData.sale?.order_type === 'delivery' && (
                <FinishCartDetailsRow>
                  <FinishCartDetailsLabel primaryColor={primaryColor}>
                    ENTREGA
                  </FinishCartDetailsLabel>
                  <FinishCartDetailsValue>
                    {orderData.sale.address?.address} {orderData.sale.address?.address_number}
                    <br />
                    {orderData.sale.address?.address_complement}
                    <br />
                    {orderData.sale.address?.city}
                    <br />
                    {orderData.sale.address?.state}
                  </FinishCartDetailsValue>
                </FinishCartDetailsRow>
              )}
              {orderData.sale?.order_type === 'pickup' && siteConfig.store?.address && (
                <FinishCartDetailsRow>
                  <FinishCartDetailsLabel primaryColor={primaryColor}>
                    RETIRADA
                  </FinishCartDetailsLabel>
                  <FinishCartDetailsValue>
                    {siteConfig.store?.address?.address} {siteConfig.store?.address?.address_number}
                    <br />
                    {siteConfig.store?.address?.address_complement}
                    <br />
                    {siteConfig.store?.address?.city}
                    <br />
                    {siteConfig.store?.address?.state}
                  </FinishCartDetailsValue>
                </FinishCartDetailsRow>
              )}
              {orderData.sale.customer.name && (
                <FinishCartDetailsRow>
                  <FinishCartDetailsLabel primaryColor={primaryColor}>
                    IDENTIFICAÇÃO
                  </FinishCartDetailsLabel>
                  <FinishCartDetailsValue>
                    {orderData.sale.customer?.name} - {orderData.sale.customer?.email}
                  </FinishCartDetailsValue>
                </FinishCartDetailsRow>
              )}
              {orderData.sale.annotation && (
                <FinishCartDetailsRow>
                  <FinishCartDetailsLabel primaryColor={primaryColor}>
                    OBSERVAÇÃO
                  </FinishCartDetailsLabel>
                  <FinishCartDetailsValue>{orderData.sale.annotation}</FinishCartDetailsValue>
                </FinishCartDetailsRow>
              )}
            </FinishCartDetailsSection>
          </FinishCartOrderDetails>
          <ButtonRounded
            onClick={redirectToStore}
            auxColor={auxColor}
            primaryColor={primaryColor}
            isDarkTheme={isDarkTheme}
          >
            Continuar comprando
          </ButtonRounded>
          {authCustomer && (
            <ButtonRounded
              onClick={redirectToSales}
              auxColor={primaryColor}
              primaryColor={auxColor}
              isDarkTheme={isDarkTheme}
            >
              Meus Pedidos
            </ButtonRounded>
          )}
        </FinishCartContainer>
      )}
      {showLoading && (
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignSelf: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '10%',
          }}
        >
          <LoadingSpinner />
        </div>
      )}
    </ConfirmationStep>
  );
};
