import styled from 'styled-components';

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  nav ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

export const PaginationItem = styled.button`
  background-color: ${(props) => (props.isDarkTheme ? props.auxColor : props.primaryColor)};
  color: ${(props) => (props.isDarkTheme ? props.primaryColor : props.auxColor)};
  border: 1px solid ${(props) => (props.isDarkTheme ? props.primaryColor : props.auxColor)};
  padding: 10px 15px;
  margin: 0 5px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};
  transition: background-color 0.3s, color 0.3s, border 0.3s;

  &:hover {
    background-color: ${(props) => (props.isDarkTheme ? props.primaryColor : props.auxColor)};
    color: ${(props) => (props.isDarkTheme ? props.auxColor : props.primaryColor)};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;
