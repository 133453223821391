import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const CustomerHeaderContainer = styled.div`
  flex: 1;
  align-self: center;
  padding: 20px 0px;
  margin-top: 10px;
`;

export const CustomerHeaderLink = styled(Link)`
  padding: 10px 15px;
  text-decoration: none;
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  color: ${(props) => (props.primaryColor === '#FFFFFF' ? props.primaryColor : 'black')};
  border: thin solid ${(props) => (props.color === '#FFFFFF' ? props.color : 'black')};
  background-color: ${(props) => (props.selected ? props.color : 'transparent')};
  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 0px;
  }
  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;
