/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
const unmask = (value) => value.replace(/\D/g, '');

export function numberToReal(number) {
  number = Number.isInteger(number) ? String(parseFloat(number)) : String(number);
  const position = Math.floor(number.length - 2);
  number = `${number.substr(0, position)}.${number.substr(position)}`;
  number = number.split('.');
  number[0] = `R$ ${number[0].split(/(?=(?:...)*$)/).join('.')}`;
  number[0] = number[0] === 'R$ ' ? 'R$ 0' : number[0];
  number[1] = number[1] === '0' ? '00' : number[1];
  return number.join().replace(',,', ',');
}

function dateFormat(date) {
  if (date) {
    date = date.split(' ');
    return date[0].split('-').reverse().join('/');
  }
  return null;
}

function removeAllSpaces(arg) {
  arg = String(arg);
  return arg.split(' ').join('');
}

function urlSerialize(data) {
  let str = '';
  for (const key in data) {
    if (str !== '') {
      str += '&';
    }
    if (typeof data[key] !== 'string') {
      for (const k in data[key]) {
        if (data[key][k]) {
          str += `${key}[${k}]=${encodeURIComponent(data[key][k])}`;
          if (str !== '') {
            str += '&';
          }
        }
      }
    } else {
      str += `${key}=${encodeURIComponent(data[key])}`;
    }
  }
  return str;
}

function processErrosReturnApiCheckout(data) {
  const { errors } = data;

  if (errors) {
    const ret = {
      message: 'Erro ao realizar o processo.',
      success: false,
      data,
      errors,
    };
    if (typeof errors !== 'string') {
      // eslint-disable-next-line no-unused-vars
      Object.entries(errors).forEach(([key, value]) => {
        ret.message = value.length > 0 ? value[0].toString() : value.toString();
      });
    } else {
      ret.message = errors;
    }
    return ret;
  }
  return {
    message: 'Erro ao realizar o processo.',
    success: false,
    data,
    errors,
  };
}

function addSecondsCurrentDateReturnMilliseconds(seconds = 0) {
  return new Date(new Date().setSeconds(seconds)).getTime();
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function convertMsToMinutes(milliseconds, round = true) {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);

  seconds = seconds % 60;
  // 👇️ if seconds are greater than 30, round minutes up (optional)
  if (round) {
    minutes = seconds >= 30 ? minutes + 1 : minutes;
  }

  minutes = minutes % 60;
  return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
}

function checkDateGreaterThanDateCurrent(time) {
  if (time) {
    const dateCurrent = new Date();
    const dateCompare = new Date(Number(time));
    if (dateCompare > dateCurrent) {
      return true;
    }
    return false;
  }
  return false;
}

function encodeSearchUri(uri, simbol, hash) {
  return encodeURIComponent(uri.split(simbol).join(hash));
}

function decodeSearchUri(uri) {
  return decodeURIComponent(uri);
}

function getTime(date) {
  var dateSplit = date.split('T');
  var time = dateSplit[1].substring(0, 5);
  return time;
}

function getDate(date) {
  var dateSplit = date.split('T');
  var time = dateSplit[0].substring(0, 10);
  return dateFormat(time);
}

function formatHoursMinutes(hours, minutes) {
  let finalHours;
  let finalMinutes;

  if (hours > 9) {
    finalHours = hours;
  } else {
    finalHours = `0${hours}`;
  }
  if (minutes > 9) {
    finalMinutes = minutes;
  } else {
    finalMinutes = `0${minutes}`;
  }

  return `${finalHours}:${finalMinutes}`;
}

function formatPeriod(interval, interval_count) {
  if (interval === 'lifetime') {
    return 'Vitalício';
  }

  if (interval === 'months') {
    if (interval_count < 2) {
      return `${interval_count} mês`;
    }
    return `${interval_count} meses`;
  }

  if (interval === 'days') {
    if (interval_count < 2) {
      return `${interval_count} dia`;
    }
    return `${interval_count} dias`;
  }

  return 'Intervalo inválido';
}

function timeDifference(date) {
  var current = new Date().getTime();
  var previous = new Date(date).getTime();

  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    //  return Math.round(elapsed/1000) + ' segundos atrás';
    return 'agora';
  } else if (elapsed < msPerHour) {
    return 'Há ' + Math.round(elapsed / msPerMinute) + ' minutos';
  } else if (elapsed < msPerDay) {
    var timeHour = Math.round(elapsed / msPerHour);
    if (timeHour === 1) {
      return 'Há ' + timeHour + ' hora';
    } else {
      return 'Há ' + timeHour + ' horas';
    }
  } else if (elapsed < msPerMonth) {
    return 'Há ' + Math.round(elapsed / msPerDay) + ' dias';
  } else if (elapsed < msPerYear) {
    return 'Há ' + Math.round(elapsed / msPerMonth) + ' meses';
  } else {
    return 'Há ' + Math.round(elapsed / msPerYear) + ' anos';
  }
}

function timeDifferenceInDays(date) {
  var current = new Date().getTime();
  var previous = new Date(date).getTime();

  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;

  var elapsed = current - previous;

  if (elapsed < msPerDay) {
    return 'Hoje';
  } else {
    return date;
  }
}

function convertHexToEmoji(emojiHex) {
  var hexPoints = emojiHex.split(' ');

  var codePoints = hexPoints.map((hex) => parseInt(hex, 16));
  var emoji = String.fromCodePoint(null, codePoints);
  return emoji;
}

function getSubdomain() {
  var domain = /:\/\/([^/]+)/.exec(window.location.href)[1];
  var subdomain = domain.split('.');
  if (subdomain.length >= 2) {
    return subdomain[0];
  }
  return null;
}

function getBalanceByColor(hex) {
  const r = Number(`0x${hex[1]}${hex[2]}`);
  const g = Number(`0x${hex[3]}${hex[4]}`);
  const b = Number(`0x${hex[5]}${hex[6]}`);

  const balance = (r + g + b) / 3;

  return balance;
}

function getColorByBalance(balance) {
  const hex = Math.floor(balance * 4).toString(16);
  return `#${hex}${hex}${hex}`;
}

function promotionalPercentage(originalPrice, promotionalPrice) {
  return parseInt(Math.round(((originalPrice - promotionalPrice) / originalPrice) * 100));
}

function formatToBrlDate(date) {
  const arrDate = date.split('-');
  return arrDate[2] + '/' + arrDate[1] + '/' + arrDate[0];
}

const Helpers = {
  unmask,
  urlSerialize,
  numberToReal,
  dateFormat,
  removeAllSpaces,
  processErrosReturnApiCheckout,
  addSecondsCurrentDateReturnMilliseconds,
  checkDateGreaterThanDateCurrent,
  encodeSearchUri,
  decodeSearchUri,
  formatHoursMinutes,
  formatPeriod,
  getDate,
  getTime,
  timeDifference,
  timeDifferenceInDays,
  convertMsToMinutes,
  convertHexToEmoji,
  getSubdomain,
  getBalanceByColor,
  getColorByBalance,
  promotionalPercentage,
  formatToBrlDate,
};

export default Helpers;
