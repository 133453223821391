import React, { useState, useEffect, createContext } from 'react';
import { getCustomer } from '../services/Auth';

export const AuthContext = createContext({});

export const AuthContextProvider = ({ children }) => {
  const [authCustomerToken, setAuthCustomerToken] = useState(
    JSON.parse(localStorage.getItem('auth_customer_token'))
  );
  const [authCustomer, setAuthCustomer] = useState(null);

  useEffect(() => {
    const fetchCustomerData = async () => {
      const { data } = await getCustomer(authCustomerToken);
      setAuthCustomer(data);
    };

    if (authCustomerToken) {
      localStorage.setItem('auth_customer_token', JSON.stringify(authCustomerToken));
      if (!authCustomer) {
        fetchCustomerData();
      }
    } else {
      localStorage.setItem('auth_customer_token', null);
      setAuthCustomer(null);
    }
  }, [authCustomerToken, authCustomer]);

  return (
    <AuthContext.Provider
      value={{ authCustomerToken, setAuthCustomerToken, authCustomer, setAuthCustomer }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
