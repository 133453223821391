import { useContext } from 'react';
import { TagsNavVerticalWrapper } from './styles';
import useColors from '../../hooks/useColors';
import ProductsSearchContext from '../../contexts/ProductsSearchContext';

const VerticalNav = () => {
  const { categories, categoryFilter, setCategoryFilter } = useContext(ProductsSearchContext);

  const activeIndex = categories.findIndex((category) => category.id === categoryFilter);

  const { primaryColor, isDarkTheme, auxColor } = useColors();

  return (
    <TagsNavVerticalWrapper
      primaryColor={primaryColor}
      auxColor={auxColor}
      isDarkTheme={isDarkTheme}
    >
      <p
        className="active"
        style={{
          position: 'absolute',
          bottom: '0',
          left: `${activeIndex * 7.5 + 0.75}rem`,
          transition: 'all 0.4s ease',
        }}
      ></p>
      {categories?.map((category) => (
        <p key={category.id} onClick={() => setCategoryFilter(category.id)}>
          {category.name}
        </p>
      ))}
    </TagsNavVerticalWrapper>
  );
};

export default VerticalNav;
