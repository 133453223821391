import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ProfileCard = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const ProfileContactForm = styled.form`
  width: 30%;
  align-self: center;
  flex: 1;
  flex-direction: column;
  padding: 1.5em;
  margin-top: 2em;

  @media (max-width: 996px) {
    padding: 0em;
    width: 50%;
  }

  @media (max-width: 768px) {
    padding: 0em;
    width: 70%;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    z-index: 1000;
  }
`;

export const ForgotPasswordForm = styled.form`
  width: 30%;
  align-self: center;
  flex: 1;
  flex-direction: column;
  padding: 1.5em;
  margin-top: 2em;

  @media (max-width: 996px) {
    padding: 0em;
    width: 50%;
  }

  @media (max-width: 768px) {
    padding: 0em;
    width: 70%;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
    z-index: 1000;
  }
`;

export const FormTitle = styled.h1`
  font-size: 1.3rem;
  font-weight: 600;
  color: ${({ primaryColor, auxColor, isDarkTheme }) => (isDarkTheme ? primaryColor : auxColor)};
`;

export const FormLabel = styled.label`
  margin-bottom: 0.25rem;
  font-size: 0.9rem;
  font-weight: 400;
`;

export const FormInput = styled.input`
  border: 1px solid #ccc;
  border-radius: 8px;
  height: 1.3rem;
  padding: 1.3rem 0.7rem;
  &:focus {
    outline: 1px solid gray;
  }
  ${({ hasError }) =>
    hasError &&
    `
    border-color: #eb5757;
  `}
`;

export const FormRow = styled.div`
  display: flex;
  margin-top: 1em;
  @media (min-width: 768px) {
    gap: 1rem;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FormCol = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    width: ${(props) => (props.width === '100%' ? props.width : `calc(${props.width} - 0.5rem)`)};
  }
`;

export const FormErrorSpan = styled.span`
  font-size: 0.75rem;
  color: #eb5757;
`;

export const RegisterLink = styled(Link)`
  text-decoration: none;
  align-self: center;
  font-size: 1rem;
  cursor: pointer;

  color: ${({ primaryColor, auxColor, isDarkTheme }) => (isDarkTheme ? primaryColor : auxColor)};

  &:visited {
    color: ${({ primaryColor, auxColor, isDarkTheme }) => (isDarkTheme ? primaryColor : auxColor)};
  }

  &:active {
    color: ${({ primaryColor, auxColor, isDarkTheme }) => (isDarkTheme ? primaryColor : auxColor)};
  }

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
    text-decoration: underline;
  }
`;

export const ForgotPasswordLink = styled.a`
  text-decoration: none;
  align-self: center;
  font-size: 1rem;
  color: ${({ isDarkTheme, primaryColor, auxColor }) => (isDarkTheme ? primaryColor : auxColor)};
  cursor: pointer;
  padding-bottom: 10px;

  &:hover {
    text-decoration: underline;
  }
`;

export const ProfileContactLoading = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: center;
  margin-top: 10%;
  align-items: center;
`;

export const ButtonRounded = styled.button`
  width: 100%;
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.7rem;
  border: none;
  cursor: pointer;
  transition: box-shadow 0.3s ease;

  background-color: ${(props) => props.primaryColor};
  color: ${(props) => props.auxColor};
  border: ${(props) => (!props.isDarkTheme ? '1px solid black' : 'none')};

  margin: 1rem 0 1rem auto;
  @media (min-width: 768px) {
    margin: 1.2rem 0 1rem auto;
  }
`;
