import useColors from '../../hooks/useColors';
import { PaginationWrapper, PaginationItem } from './styles';

function Pagination({ page, setPage, lastPage }) {
  const { primaryColor, auxColor, isLightTheme, isDarkTheme } = useColors();

  const getPagination = () => {
    const pageList = [];
    const range = 2; // Define a quantidade de páginas a serem exibidas antes e depois da página atual

    // Adiciona a primeira página
    if (page > 1) {
      pageList.push(
        <li key={1}>
          <PaginationItem
            active={page === 1}
            primaryColor={primaryColor}
            auxColor={auxColor}
            isDarkTheme={isDarkTheme}
            onClick={() => setPage(1)}
          >
            1
          </PaginationItem>
        </li>
      );
      // Adiciona "..." se não estiver próximo da primeira página
      if (page > range + 1) {
        pageList.push(<li key="start-ellipsis">...</li>);
      }
    }

    // Adiciona páginas próximas à página atual
    for (let i = Math.max(2, page - range); i <= Math.min(lastPage - 1, page + range); i++) {
      pageList.push(
        <li key={i}>
          <PaginationItem
            disabled={page === i}
            active={page === i}
            primaryColor={primaryColor}
            auxColor={auxColor}
            isDarkTheme={isDarkTheme}
            onClick={() => setPage(i)}
          >
            {i}
          </PaginationItem>
        </li>
      );
    }

    // Adiciona a última página
    if (page < lastPage) {
      // Adiciona "..." se não estiver próximo da última página
      if (page < lastPage - range) {
        pageList.push(<li key="end-ellipsis">...</li>);
      }
      pageList.push(
        <li key={lastPage}>
          <PaginationItem
            active={page === lastPage}
            primaryColor={primaryColor}
            auxColor={auxColor}
            isDarkTheme={isDarkTheme}
            onClick={() => setPage(lastPage)}
          >
            {lastPage}
          </PaginationItem>
        </li>
      );
    }

    return pageList;
  };

  return (
    <PaginationWrapper primaryColor={primaryColor} auxColor={auxColor} isLightTheme={isLightTheme}>
      <nav>
        <ul>
          <li>
            <PaginationItem
              disabled={page === 1}
              primaryColor={primaryColor}
              auxColor={auxColor}
              isDarkTheme={isDarkTheme}
              onClick={() => page > 1 && setPage(page - 1)}
            >
              &laquo;
            </PaginationItem>
          </li>
          {getPagination()}
          <li>
            <PaginationItem
              disabled={page === lastPage}
              primaryColor={primaryColor}
              auxColor={auxColor}
              isDarkTheme={isDarkTheme}
              onClick={() => page < lastPage && setPage(page + 1)}
            >
              &raquo;
            </PaginationItem>
          </li>
        </ul>
      </nav>
    </PaginationWrapper>
  );
}

export default Pagination;
