import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';

import {
  P,
  QuantityInput,
  CardProduct,
  MobileCardProduct,
  CancelButton,
  ProductName,
} from './styles';
import { numberToReal } from '../../utils/helpers';
import CheckoutContext from '../../contexts/CheckoutContext';
import LoadingSpinner from '../Loading/LoadingSpinner';
import useColors from '../../hooks/useColors';

export const ProductCard = ({ product, progress }) => {
  const { setCheckoutProducts, checkIfProductStillExists } = useContext(CheckoutContext);
  const { primaryColor, auxColor, isDarkTheme } = useColors();
  const [loadingQuantity, setLoadingQuantity] = useState(false);

  const setProductQuantityById = async (productId, quantity) => {
    setLoadingQuantity(true);
    const exists = await checkIfProductStillExists(productId, quantity);
    if (exists) {
      setCheckoutProducts((state) =>
        state.map((fproduct) =>
          fproduct.id === productId ? { ...fproduct, quantity: quantity } : fproduct
        )
      );
    }
    setLoadingQuantity(false);
  };

  const removeItem = () => {
    setCheckoutProducts((state) => state.filter((fproduct) => fproduct.id !== product.id));
  };

  return (
    <>
      <CardProduct>
        <div>
          {product.image ? (
            <img className="product-image" src={product.image} alt="Imagem do produto" />
          ) : (
            <div className="product-image">
              <i className="fa-solid fa-camera"></i>
            </div>
          )}

          <NavLink to={'/produtos/' + product.id} style={{ textDecoration: 'none' }}>
            <ProductName>{product.name}</ProductName>
          </NavLink>
        </div>
        <P className="price">{numberToReal(product.price)}</P>
        {!loadingQuantity && (
          <QuantityInput primaryColor={primaryColor} auxColor={auxColor} isDarkTheme={isDarkTheme}>
            <>
              <button onClick={() => setProductQuantityById(product.id, product.quantity - 1)}>
                -
              </button>
              {product.quantity}
              <button onClick={() => setProductQuantityById(product.id, product.quantity + 1)}>
                +
              </button>
            </>
          </QuantityInput>
        )}
        {loadingQuantity && (
          <div style={{ width: '70px', height: '50px' }}>
            <LoadingSpinner
              style={{ width: '2rem', height: '2rem' }}
              color={primaryColor === '#FFFFFF' ? primaryColor : 'black'}
            />
          </div>
        )}

        <P className="total">{numberToReal((product.price * product.quantity).toFixed(2))}</P>
        {progress < 3 && (
          <CancelButton onClick={removeItem} className="cancel-button">
            <i className="fa-solid fa-xmark" />
          </CancelButton>
        )}
      </CardProduct>
      <MobileCardProduct primaryColor={primaryColor} auxColor={auxColor} isDarkTheme={isDarkTheme}>
        <div>
          {product.image ? (
            <img className="product-image" src={product.image} alt="Imagem do produto" />
          ) : (
            <div className="product-image">
              <i className="fa-solid fa-camera"></i>
            </div>
          )}
        </div>
        <div style={{ display: 'flex' }}>
          <P>{product.name}</P>
          <QuantityInput primaryColor={primaryColor} auxColor={auxColor} isDarkTheme={isDarkTheme}>
            <button onClick={() => setProductQuantityById(product.id, product.quantity - 1)}>
              -
            </button>
            {product.quantity}
            <button onClick={() => setProductQuantityById(product.id, product.quantity + 1)}>
              +
            </button>
          </QuantityInput>
          <P className="price">
            {numberToReal(parseFloat(product.price * product.quantity).toFixed(2))}
          </P>
        </div>
        {progress < 3 && (
          <CancelButton onClick={removeItem} className="cancel-button">
            <i className="fa-solid fa-xmark" />
          </CancelButton>
        )}
      </MobileCardProduct>
    </>
  );
};
