import React, { useState, useEffect, createContext } from 'react';
import { toast } from 'react-toastify';
import { siteRead } from '../services/Site';
import Helpers from '../utils/helpers';

export const SiteConfigContext = createContext({});

export const SiteConfigProvider = ({ children }) => {
  const [subdomain, setSubdomain] = useState(null);
  const [siteConfig, setSiteConfig] = useState(null);
  const [loadingSiteState, setLoadingSiteState] = useState(true);

  const readSiteConfig = async () => {
    const subdomainStr = Helpers.getSubdomain();

    if (!subdomainStr) {
      toast.error('Erro ao carregar os dados da loja.');
      setSubdomain(false);
      setLoadingSiteState(false);
      return;
    }

    setSubdomain(subdomainStr);

    const { data, success, message } = await siteRead(subdomainStr);

    setLoadingSiteState(false);

    if (!success) {
      setSubdomain(null);
      throw new Error(message);
    }

    setSiteConfig(data);

    return data;
  };

  useEffect(() => {
    readSiteConfig().then(({ store }) => {
      document.title = store.name;
    });
  }, []);

  return (
    <SiteConfigContext.Provider
      value={{
        subdomain,
        siteConfig,
        loadingSiteState,
      }}
    >
      {children}
    </SiteConfigContext.Provider>
  );
};

export default SiteConfigContext;
