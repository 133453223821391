import { Row } from './styles';

export const Icon = ({ iconClasses, href, auxColor }) => (
  <a target="_blank" href={href} rel="noreferrer">
    <i className={iconClasses} style={{ color: auxColor, fontSize: '1.5rem' }}></i>
  </a>
);

export const IconList = ({ siteConfig, auxColor }) => {
  return (
    <Row>
      {siteConfig?.configs?.whatsapp && (
        <Icon
          iconClasses="fa-brands fa-whatsapp"
          href={`${siteConfig?.configs?.whatsapp}&text=Olá! Estou com uma dúvida, pode me ajudar?`}
          auxColor={auxColor}
        />
      )}
      {siteConfig?.configs?.instagram && (
        <Icon
          iconClasses="fa-brands fa-instagram"
          href={siteConfig?.configs?.instagram}
          auxColor={auxColor}
        />
      )}
      {siteConfig?.configs?.facebook && (
        <Icon
          iconClasses="fa-brands fa-facebook-f"
          href={siteConfig?.configs?.facebook}
          auxColor={auxColor}
        />
      )}
      {siteConfig?.configs?.twitter && (
        <Icon
          iconClasses="fa-brands fa-twitter"
          href={siteConfig?.configs?.twitter}
          auxColor={auxColor}
        />
      )}
    </Row>
  );
};
