import React, { useEffect, useContext, useState } from 'react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SiteContext from '../../contexts/SiteConfigContext';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { BannerWrapper } from './styles';

function Banner() {
  const { siteConfig } = useContext(SiteContext);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    setBanners(siteConfig?.images);
  }, [siteConfig]);

  if (!banners || !banners.length) return null;

  if (banners?.length === 1) {
    return (
      <BannerWrapper>
        <img
          src={banners[0].url}
          alt=""
          style={{
            aspectRatio: '1',
            maxHeight: '40vh',
            borderRadius: '10px',
          }}
        />
      </BannerWrapper>
    );
  }

  return (
    <BannerWrapper>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        slidesPerView={1}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 5000,
        }}
        navigation
        loop
      >
        {banners.map((banner) => (
          <SwiperSlide key={banner.url}>
            <img src={banner.url} alt="" />
          </SwiperSlide>
        ))}
      </Swiper>
    </BannerWrapper>
  );
}

export default Banner;
