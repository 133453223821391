import React, { useEffect, useContext, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ImageZoom from 'js-image-zoom';

import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import SiteContext from '../../contexts/SiteConfigContext';
import useColors from '../../hooks/useColors';
import { productRead } from '../../services/Products';
import Helpers from '../../utils/helpers';
import {
  ProductWrapper,
  DescriptionWrapper,
  Row,
  PromotionalPriceView,
  PromotionalTag,
  WhatsappLink,
} from './styles';
import LoadingSpinner from '../../components/Loading/LoadingSpinner';
import CheckoutContext from '../../contexts/CheckoutContext';

const ProductImagesSlider = ({ images, image, promotionalTagPrice = null }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!images?.length && !image) return;
    if (!ref.current) return;

    const imgZoom = new ImageZoom(ref.current, {
      fillContainer: true,
      offset: { vertical: 0, horizontal: ref.current.offsetWidth * -1 },
    });

    return () => {
      imgZoom.kill();
    };
  }, [images?.length, image]);

  if (!images?.length && !image)
    return (
      <>
        <div className="product-image image-placeholder" ref={ref}>
          {promotionalTagPrice && <PromotionalTag>{promotionalTagPrice}% OFF</PromotionalTag>}

          <i className="fa-solid fa-camera"></i>
        </div>
      </>
    );

  if (!images?.length && image)
    return (
      <div className="product-image">
        {promotionalTagPrice && <PromotionalTag>{promotionalTagPrice}% OFF</PromotionalTag>}

        <div ref={ref}>
          <img src={image} alt="Imagem do produto" />
        </div>
      </div>
    );

  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      slidesPerView={1}
      pagination={{ clickable: true }}
      autoplay={{
        delay: 2000,
      }}
      navigation
      loop
      className="product-image"
      ref={ref}
    >
      <SwiperSlide>
        {promotionalTagPrice && <PromotionalTag>{promotionalTagPrice}% OFF</PromotionalTag>}
        <img src={image} alt="Imagem do produto" />
      </SwiperSlide>
      {images.map((img) => (
        <SwiperSlide>
          {promotionalTagPrice && <PromotionalTag>{promotionalTagPrice}% OFF</PromotionalTag>}
          <img src={img.url} alt="Imagem do produto" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

const Product = () => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const { siteConfig, subdomain } = useContext(SiteContext);
  const [product, setProduct] = useState(null);
  const { checkoutProducts, setCheckoutProducts } = useContext(CheckoutContext);

  const { primaryColor, isDarkTheme, auxColor } = useColors();

  const getProductSaleUrl = () => {
    const url = window.location.href;
    const saleUrl = `${siteConfig.configs.whatsapp}&text=Olá, gostaria de encomendar o produto ${product?.name}: ${url}`;
    return saleUrl.replaceAll('phone=+', 'phone=');
  };

  useEffect(() => {
    if (!productId) return;

    productRead(subdomain, productId).then(({ data, success }) => {
      if (!success) return toast.error('Produto não encontrado!');

      setProduct(data.product);
    });
  }, [productId, subdomain]);

  const checkIfProductExistsOnCart = (productId) => {
    return checkoutProducts.some((productOnCart) => productOnCart.id === productId);
  };

  const handleCartAdd = () => {
    if (checkIfProductExistsOnCart(product.id)) {
      const updatedCheckoutProducts = checkoutProducts.map((productOnCart) => {
        if (productOnCart.id === product.id) {
          productOnCart.quantity++;
        }
        return { ...productOnCart };
      });
      setCheckoutProducts(updatedCheckoutProducts);
    } else {
      setCheckoutProducts((state) => [
        ...state,
        {
          id: product.id,
          sell_price: product.sell_price,
          price: product.promotional_price ? product.promotional_price : product.sell_price,
          quantity: 1,
          name: product.name,
          image: product.image || product.images[0],
          is_promotional: product.promotional_price ? true : false,
        },
      ]);
    }

    navigate('/carrinho');
  };

  const handleCallStore = (e) => {
    const url =
      siteConfig?.configs?.whatsapp +
      '&text=Olá! Estou com dúvidas sobre o produto "' +
      product.name +
      '", pode me ajudar?';
    window.open(url);
  };

  if (!product)
    return (
      <>
        <Header />
        <div style={{ height: '100%', margin: 'auto' }}>
          <LoadingSpinner color={isDarkTheme ? primaryColor : auxColor} />
        </div>
        <Footer />
      </>
    );

  return (
    <>
      <Header />
      <ProductWrapper isDarkTheme={isDarkTheme} primaryColor={primaryColor} auxColor={auxColor}>
        <Link to="/" className="breadcrumb">
          <i className="fa-solid fa-arrow-left"></i> Voltar
        </Link>

        <Row>
          <ProductImagesSlider
            images={product?.images}
            image={product?.image}
            promotionalTagPrice={
              product.promotional_price
                ? Helpers.promotionalPercentage(product.sell_price, product.promotional_price)
                : null
            }
          />

          <div>
            <h1>{product.name}</h1>
            {siteConfig?.store?.configs?.products_listing === 2 && product.amount < 1 && (
              <h3 className="text-error">Estoque indisponível</h3>
            )}
            <h3>
              <b>{product.category?.name}</b>
            </h3>
            {product.promotional_price ? (
              <PromotionalPriceView>
                <span>{Helpers.numberToReal(product.sell_price)}</span>
                <h4>{Helpers.numberToReal(product.promotional_price)}</h4>
              </PromotionalPriceView>
            ) : (
              <h4>{Helpers.numberToReal(product.sell_price)}</h4>
            )}
            <div className="buy-buttons">
              {siteConfig?.configs?.whatsapp && siteConfig?.configs?.whatsapp !== '' && (
                <a
                  target="_blank"
                  href={getProductSaleUrl()}
                  className="buy-button"
                  rel="noreferrer"
                >
                  <i className="fa-brands fa-whatsapp"></i> Comprar no whatsapp
                </a>
              )}
              {(siteConfig?.store?.configs?.products_listing === 2 && product.amount >= 1) ||
              siteConfig?.store?.configs?.products_listing !== 2 ? (
                <button className="buy-button" onClick={handleCartAdd}>
                  <i className="fa-solid fa-cart-shopping"></i> Adicionar ao carrinho
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Row>
        {product.description && (
          <>
            <DescriptionWrapper>
              <h2>Informações do Produto</h2>
              {product.description}
            </DescriptionWrapper>
          </>
        )}
        {siteConfig?.configs?.whatsapp && siteConfig?.configs?.whatsapp !== '' && (
          <Row className="row-whatsapp">
            <WhatsappLink
              isDarkTheme={isDarkTheme}
              primaryColor={primaryColor}
              auxColor={auxColor}
              target="_blank"
              href=""
              onClick={handleCallStore}
            >
              Dúvidas sobre este produto? Entre em contato conosco pelo nosso WhatsApp!{' '}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="currentColor"
                className="bi bi-whatsapp"
                viewBox="0 0 16 16"
              >
                <path d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232" />
              </svg>
            </WhatsappLink>
          </Row>
        )}
      </ProductWrapper>
      <Footer />
    </>
  );
};

export default Product;
