import React, { useState, useEffect, createContext, useContext } from 'react';
import { productRead } from '../services/Products';
import { toast } from 'react-toastify';
import SiteConfigContext from '../contexts/SiteConfigContext';
export const CheckoutContext = createContext({});

export const CheckoutContextProvider = ({ children }) => {
  const CHECKOUT_KEY = window.location.hostname + '_checkout_products';
  const { subdomain } = useContext(SiteConfigContext);
  const [checkoutProducts, setCheckoutProducts] = useState(
    JSON.parse(localStorage.getItem(CHECKOUT_KEY)) || []
  );
  const [checkoutNote, setCheckoutNote] = useState('');

  const clearCheckout = () => {
    localStorage.removeItem(CHECKOUT_KEY);
    setCheckoutProducts([]);
    setCheckoutNote('');
  };

  const checkIfProductsStillExists = async () => {
    if (subdomain) {
      checkoutProducts.forEach(async (product) => {
        const productResponse = await productRead(subdomain, product.id);
        if (productResponse.data.product.amount === 0) {
          setCheckoutProducts((state) => state.filter((p) => p.id !== product.id));
          toast.warning('O produto "' + product.name + '" não está mais disponível!');
        }
      });
    }
  };

  const checkIfProductStillExists = async (productId, quantity) => {
    if (subdomain) {
      const productResponse = await productRead(subdomain, productId);

      if (productResponse.data.product.amount >= quantity) {
        return true;
      } else {
        toast.warning(
          'Não há estoque para o produto "' + productResponse.data.product.name + '" disponível!'
        );
      }
    }
    return false;
  };

  useEffect(
    () => localStorage.setItem(CHECKOUT_KEY, JSON.stringify(checkoutProducts)),
    [CHECKOUT_KEY, checkoutProducts]
  );

  return (
    <CheckoutContext.Provider
      value={{
        checkoutProducts,
        setCheckoutProducts,
        checkoutNote,
        setCheckoutNote,
        clearCheckout,
        checkIfProductStillExists,
        checkIfProductsStillExists,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutContext;
