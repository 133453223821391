import { useState, useContext, useEffect, useRef } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import * as z from 'zod';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import useColors from '../../hooks/useColors';
import {
  ProfileCard,
  FormRow,
  FormCol,
  FormTitle,
  FormLabel,
  FormInput,
  FormErrorSpan,
  ProfileContactForm,
  ButtonRounded,
  ProfileContactLoading,
  RegisterLink,
  ForgotPasswordLink,
  ForgotPasswordForm,
} from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import LoadingSpinner from '../../components/Loading/LoadingSpinner';
import AuthContext from '../../contexts/AuthContext';
import SiteContext from '../../contexts/SiteConfigContext';
import {
  processAuthCustomer,
  forgotPasswordCustomer,
  validateCodeCustomer,
  updatePasswordCustomer,
} from '../../services/Auth';

const schemaLogin = z.object({
  email: z.string().min(1, 'O e-mail é obrigatório!').email('E-mail inválido!'),
  password: z.string().min(1, 'A senha é obrigatória!'),
});

const schemaForgotPassword = z.object({
  email: z.string().min(1, 'O e-mail é obrigatório!').email('E-mail inválido!'),
});

const schemaNewPassword = z
  .object({
    newPassword: z.string().min(6, 'A senha deve ter pelo menos 6 caracteres!'),
    confirmPassword: z.string().min(6, 'A senha de confirmação é obrigatória!'),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: 'As senhas não coincidem!',
    path: ['confirmPassword'],
  });

const LoginPage = () => {
  const formRef = useRef(null);
  const formRefPassword = useRef(null);
  const navigate = useNavigate();
  const { auxColor, primaryColor, isDarkTheme } = useColors();
  const { authCustomer, setAuthCustomerToken, setAuthCustomer } = useContext(AuthContext);
  const { siteConfig } = useContext(SiteContext);

  const [loginState, setLoginState] = useState({ email: '', password: '' });
  const [loginErrors, setLoginErrors] = useState({});
  const [showFormLoading, setShowFormLoading] = useState(false);

  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [forgotPasswordErrors, setForgotPasswordErrors] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [formStep, setFormStep] = useState('enterEmail');
  const [activationCode, setActivationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loadingStep, setLoadingStep] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleEmailChange = (e) => {
    setLoginState({ ...loginState, email: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setLoginState({ ...loginState, password: e.target.value });
  };

  const handleForgotPasswordEmailChange = (e) => {
    setForgotPasswordEmail(e.target.value);
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    const { success, error } = schemaForgotPassword.safeParse({ email: forgotPasswordEmail });

    if (!success) {
      const errors = error.format();
      setForgotPasswordErrors({
        email: errors.email && errors.email._errors[0],
      });
      return;
    }

    setLoadingStep(true);

    try {
      const data = await forgotPasswordCustomer(forgotPasswordEmail);
      if (data.success) {
        setFormStep('enterCode');
        setError('');
      } else {
        setError('Erro ao enviar o código de recuperação.');
      }
    } catch (error) {
      setError('Erro ao processar o pedido de recuperação de senha.');
    } finally {
      setLoadingStep(false);
    }
  };

  const handleValidateCode = async (e) => {
    e.preventDefault();

    setLoadingStep(true);

    try {
      const data = await validateCodeCustomer(activationCode);

      if (data.success) {
        setFormStep('enterNewPassword');
        setError('');
      } else {
        setError('Código inválido! Verifique o código enviado ao seu e-mail.');
      }
    } catch (error) {
      setError('Ocorreu um erro ao validar o código.');
    } finally {
      setLoadingStep(false);
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();

    const { success, error } = schemaNewPassword.safeParse({
      newPassword,
      confirmPassword,
    });

    if (!success) {
      const errors = error.format();
      setForgotPasswordErrors({
        newPassword: errors.newPassword && errors.newPassword._errors[0],
        confirmPassword: errors.confirmPassword && errors.confirmPassword._errors[0],
      });
      return;
    }

    setLoadingStep(true);

    try {
      const data = await updatePasswordCustomer(activationCode, newPassword);

      if (data.success) {
        setError('');
        setMessage('Senha alterada com sucesso!');
        toggleModal();
        navigate('/entrar');
      } else {
        setError('Erro ao atualizar a senha. Tente novamente.');
      }
    } catch (error) {
      setError('Erro ao atualizar a senha. Tente novamente.');
    } finally {
      setLoadingStep(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  /**
   * Processa cadastro de Customer
   * @param {*} e
   * @returns
   */
  const submitLoginHandler = async (e) => {
    e.preventDefault();
    setShowFormLoading(true);

    // const values = Object.fromEntries(new FormData(formRef.current));
    const { success, error } = schemaLogin.safeParse(loginState);

    if (!success) {
      const errors = error.format();
      setLoginErrors({
        email: errors.email && errors.email._errors[0],
        password: errors.password && errors.password._errors[0],
      });
      setShowFormLoading(false);
      return;
    }

    const data = await processAuthCustomer(
      siteConfig.store.id,
      loginState.email,
      loginState.password
    );
    if (data.success) {
      setAuthCustomerToken(data.data.token);
      setAuthCustomer(data.data.customer);
      toast.success('Seja bem-vindo, e boas compras!');
      navigate('/meus-pedidos');
    } else {
      setLoginErrors({
        email: data.message,
      });
    }
    setShowFormLoading(false);
  };

  const renderFormContent = () => {
    if (loadingStep) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <LoadingSpinner size="2rem" color={isDarkTheme ? primaryColor : auxColor} />
        </div>
      );
    }

    return formStep === 'enterEmail' ? (
      <>
        <FormTitle primaryColor={primaryColor}>Recuperar Senha</FormTitle>
        <FormRow>
          <FormCol width="100%">
            <FormLabel htmlFor="email">E-mail</FormLabel>
            <FormInput
              required
              type="email"
              name="email"
              id="email"
              placeholder="Informe seu e-mail de acesso"
              value={forgotPasswordEmail}
              onChange={handleForgotPasswordEmailChange}
              hasError={!!forgotPasswordErrors.email}
            />
            {forgotPasswordErrors.email && (
              <FormErrorSpan>{forgotPasswordErrors.email}</FormErrorSpan>
            )}
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol width="100%">
            <ButtonRounded
              type="submit"
              onClick={handleForgotPassword}
              auxColor={auxColor}
              primaryColor={primaryColor}
              isDarkTheme={isDarkTheme}
            >
              Enviar
            </ButtonRounded>
          </FormCol>
        </FormRow>
        {message && <p>{message}</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </>
    ) : formStep === 'enterCode' ? (
      <>
        <FormTitle primaryColor={primaryColor}>Código de ativação</FormTitle>
        <FormRow>
          <FormCol width="100%">
            <FormLabel htmlFor="activationCode">Código de Ativação</FormLabel>
            <FormInput
              required
              type="text"
              name="activationCode"
              id="activationCode"
              placeholder="Insira o código de ativação"
              value={activationCode}
              onChange={(e) => setActivationCode(e.target.value)}
            />
          </FormCol>
        </FormRow>
        <FormRow>
          <FormCol width="100%">
            <ButtonRounded
              onClick={handleValidateCode}
              auxColor={auxColor}
              primaryColor={primaryColor}
              isDarkTheme={isDarkTheme}
            >
              Validar
            </ButtonRounded>
          </FormCol>
        </FormRow>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </>
    ) : formStep === 'enterNewPassword' ? (
      <>
        <FormTitle primaryColor={primaryColor}>Insira uma nova senha</FormTitle>
        <div style={{ position: 'relative', width: '100%' }}>
          <FormRow>
            <FormCol width="100%">
              <FormLabel htmlFor="newPassword">Senha</FormLabel>
              <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                <FormInput
                  required
                  type={showPassword ? 'text' : 'password'}
                  name="newPassword"
                  id="newPassword"
                  placeholder="Nova Senha"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  hasError={!!forgotPasswordErrors.newPassword}
                  style={{ flex: 1 }}
                />
                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    cursor: 'pointer',
                    color: '#aaa',
                  }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              {forgotPasswordErrors.newPassword && (
                <FormErrorSpan>{forgotPasswordErrors.newPassword}</FormErrorSpan>
              )}
            </FormCol>
          </FormRow>
        </div>
        <div style={{ position: 'relative', width: '100%' }}>
          <FormRow>
            <FormCol width="100%">
              <FormLabel htmlFor="confirmPassword">Confirmar Senha</FormLabel>
              <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                <FormInput
                  required
                  type={showPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="Confirmar Senha"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  hasError={!!forgotPasswordErrors.confirmPassword}
                  style={{ flex: 1 }}
                />
                <span
                  onClick={togglePasswordVisibility}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    cursor: 'pointer',
                    color: '#aaa',
                  }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
              {forgotPasswordErrors.confirmPassword && (
                <FormErrorSpan>{forgotPasswordErrors.confirmPassword}</FormErrorSpan>
              )}
            </FormCol>
          </FormRow>
        </div>
        <FormRow>
          <FormCol width="100%">
            <ButtonRounded
              onClick={handleUpdatePassword}
              auxColor={auxColor}
              primaryColor={primaryColor}
              isDarkTheme={isDarkTheme}
            >
              Atualizar
            </ButtonRounded>
          </FormCol>
        </FormRow>
        {message && <p>{message}</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </>
    ) : (
      <div> </div>
    );
  };

  useEffect(() => {
    if (authCustomer) {
      navigate('/');
    }
  }, [authCustomer, navigate]);

  return (
    <>
      <Header />
      <ProfileCard>
        <ProfileContactForm ref={formRef}>
          {!showFormLoading && (
            <>
              <FormTitle primaryColor={primaryColor}>Efetue seu Login</FormTitle>
              <FormRow>
                <FormCol width="100%">
                  <FormLabel htmlFor="email">E-mail</FormLabel>
                  <FormInput
                    required
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Informe seu e-mail de acesso"
                    value={loginState.email}
                    onChange={handleEmailChange}
                    hasError={!!loginErrors.email}
                  />
                  {loginErrors.email && <FormErrorSpan>{loginErrors.email}</FormErrorSpan>}
                </FormCol>
              </FormRow>
              <div style={{ position: 'relative', width: '100%' }}>
                <FormRow>
                  <FormCol width="100%">
                    <FormLabel htmlFor="email">Senha</FormLabel>
                    <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                      <FormInput
                        required
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        id="password"
                        placeholder="Informe sua senha de acesso"
                        value={loginState.password}
                        onChange={handlePasswordChange}
                        hasError={!!loginErrors.password}
                        style={{ flex: 1 }}
                      />
                      <span
                        onClick={togglePasswordVisibility}
                        style={{
                          position: 'absolute',
                          right: '10px',
                          cursor: 'pointer',
                          color: '#aaa',
                        }}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                    {loginErrors.password && <FormErrorSpan>{loginErrors.password}</FormErrorSpan>}
                  </FormCol>
                </FormRow>
              </div>
              <FormRow>
                <FormCol width="100%">
                  <ButtonRounded
                    type="submit"
                    onClick={submitLoginHandler}
                    auxColor={auxColor}
                    primaryColor={primaryColor}
                    isDarkTheme={isDarkTheme}
                  >
                    Entrar
                  </ButtonRounded>
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol width="100%">
                  <ForgotPasswordLink
                    primaryColor={primaryColor}
                    auxColor={auxColor}
                    isDarkTheme={isDarkTheme}
                    onClick={toggleModal}
                  >
                    Esqueci a senha
                  </ForgotPasswordLink>
                  <RegisterLink
                    primaryColor={primaryColor}
                    auxColor={auxColor}
                    isDarkTheme={isDarkTheme}
                    to="/cadastre-se"
                  >
                    Não possui registro? Cadastre-se
                  </RegisterLink>
                </FormCol>
              </FormRow>
              {modalOpen && (
                <ForgotPasswordForm ref={formRefPassword}>
                  <div className="modal-overlay" onClick={toggleModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                      {renderFormContent()}
                    </div>
                  </div>
                </ForgotPasswordForm>
              )}
            </>
          )}
          {showFormLoading && (
            <ProfileContactLoading>
              <LoadingSpinner color={isDarkTheme ? primaryColor : auxColor} />
            </ProfileContactLoading>
          )}
        </ProfileContactForm>
      </ProfileCard>
      <Footer />
    </>
  );
};

export default LoginPage;
