import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { useIMask } from 'react-imask';
import * as z from 'zod';

import {
  ButtonRounded,
  CheckoutAddressForm,
  FormTitle,
  FormInput,
  FormLabel,
  FormRow,
  FormCol,
  FormErrorSpan,
  FormSelect,
  PickupDescription,
} from './styles';
import { toast } from 'react-toastify';
import { numberToReal } from '../../utils/helpers';
import useColors from '../../hooks/useColors';
import { NavPills } from './NavPills';
import { getAddressDataByCep, getCitiesDataByUF, getUFData } from '../../services/ViaCep';
import AuthContext from '../../contexts/AuthContext';
import SiteContext from '../../contexts/SiteConfigContext';

export const CheckoutAddressStep = ({
  maxProgress,
  progress,
  setProgress,
  formsState,
  setFormState,
}) => {
  const { siteConfig } = useContext(SiteContext);

  const formRef = useRef(null);
  const { ref: zipCodeRef } = useIMask(
    { mask: '00000-000' },
    {
      onAccept: async (value) => {
        onChangeHandler('zip_code', value);

        if (value.length !== 9) return;

        const { logradouro, bairro, complemento, uf, localidade } = await getAddressDataByCep(
          value
        );
        const cidades = await getCitiesDataByUF(uf);
        setListCities(cidades);

        onChangeHandler('street', logradouro);
        onChangeHandler('complement', complemento);
        onChangeHandler('neighborhood', bairro);
        onChangeHandler('city', localidade);
        onChangeHandler('uf', uf);
      },
    }
  );
  const [orderType, setOrderType] = useState(
    siteConfig.configs.allowPickup ? 'pickup' : siteConfig.configs.allowDelivery ? 'delivery' : null
  );
  const [selectedDeliveryType, setSelectedDeliveryType] = useState(null);
  const { primaryColor, auxColor, isDarkTheme } = useColors();
  const { authCustomer } = useContext(AuthContext);
  const [formErrors, setFormErrors] = useState({});
  const [listCities, setListCities] = useState([]);
  const [listUFs, setListUFs] = useState([]);

  const schema = z.object({
    zip_code: z
      .string()
      .min(1, 'Cep é obrigatório')
      .refine((value) => /^[0-9]{5}-[0-9]{3}$/.test(value), 'Cep inválido'),
    street: z.string().min(1, 'Endereço é obrigatório'),
    neighborhood: z.string().min(1, 'Bairro é obrigatório'),
    number: z.string(),
    complement: z.string(),
    city: z.string(),
    uf: z.string(),
  });

  const submitHandler = (e) => {
    e.preventDefault();
    setFormState((state) => ({
      ...state,
      orderType: orderType,
    }));
    if (orderType !== 'pickup') {
      const values = Object.fromEntries(new FormData(formRef.current));
      const { success, error } = schema.safeParse(values);

      if (!selectedDeliveryType) {
        toast.error('Você precisa selecionar um método de entrega!');
        return;
      }

      if (!success) {
        const errors = error.format();
        setFormErrors({
          zip_code: errors.zip_code && errors.zip_code._errors[0],
          street: errors.street && errors.street._errors[0],
          neighborhood: errors.neighborhood && errors.neighborhood._errors[0],
          number: errors.number && errors.number._errors[0],
          complement: errors.complement && errors.complement._errors[0],
          city: errors.city && errors.city._errors[0],
          uf: errors.uf && errors.uf._errors[0],
        });
        return;
      }
    }

    setProgress(5);
  };

  const getValueFromState = (name) => (formsState.address ? formsState.address[name] : '');

  const onChangeHandler = useCallback(
    (name, value) => {
      setFormState((state) => ({
        ...state,
        address: { ...state.address, [name]: value },
        deliveryType: selectedDeliveryType,
      }));
    },
    [setFormState, selectedDeliveryType]
  );

  const loadUFData = useCallback(async () => {
    const estados = await getUFData();
    await setListUFs(estados);
    onChangeHandler('uf', authCustomer?.state ? authCustomer?.state : '');
  }, [authCustomer, onChangeHandler]);

  useEffect(() => {
    loadUFData();

    const loadCities = async (uf) => {
      const cidades = await getCitiesDataByUF(uf);
      setListCities(cidades);
    };

    if (authCustomer) {
      void loadCities(authCustomer.state);
      setFormState((state) => ({
        ...state,
        address: {
          zip_code: authCustomer.cep,
          street: authCustomer.address,
          neighborhood: authCustomer.address_complement,
          number: authCustomer.address_number,
          complement: authCustomer.address_referred_point,
          city: authCustomer.city,
          uf: authCustomer.state,
        },
      }));
    }
  }, [loadUFData, authCustomer, setFormState]);

  useEffect(() => {
    if (!siteConfig.configs.allowDelivery && !siteConfig.configs.allowPickup) {
      setProgress(5);
    }
  }, [setProgress, siteConfig]);

  useEffect(() => {
    setFormState((state) => ({
      ...state,
      deliveryType: selectedDeliveryType,
    }));
  }, [setFormState, selectedDeliveryType]);

  return (
    <CheckoutAddressForm ref={formRef}>
      <NavPills
        maxProgress={maxProgress}
        progress={progress}
        setProgress={setProgress}
        formsState={formsState}
      />
      <FormTitle primaryColor={primaryColor} auxColor={auxColor} isDarkTheme={isDarkTheme}>
        Selecione como quer receber o pedido:
      </FormTitle>
      {siteConfig.configs.allowPickup && (
        <>
          <FormRow className="form-row">
            <input
              type="radio"
              name="orderType"
              id="orderType"
              value="pickup"
              checked={orderType === 'pickup'}
              onChange={() => {
                setOrderType('pickup');
              }}
            />
            <FormLabel htmlFor="orderType">Retirada</FormLabel>
          </FormRow>
          {orderType === 'pickup' && siteConfig.store?.address && (
            <PickupDescription>
              Endereço para Retirada:{' '}
              {siteConfig.store?.address?.address +
                ' ' +
                siteConfig.store?.address?.address_number +
                ' ' +
                siteConfig.store?.address?.address_complement}
            </PickupDescription>
          )}
        </>
      )}
      {siteConfig.configs.allowDelivery && (
        <>
          <FormRow className="form-row">
            <input
              type="radio"
              name="orderType"
              id="orderType2"
              value="delivery"
              checked={orderType === 'delivery'}
              onChange={() => {
                setOrderType('delivery');
              }}
            />
            <FormLabel htmlFor="orderType2">Entrega</FormLabel>
          </FormRow>
          {orderType === 'delivery' && (
            <>
              {siteConfig.configs.deliveryTypes && siteConfig.configs.deliveryTypes.length > 0 && (
                <>
                  <FormTitle primaryColor={primaryColor}>Serviço de Entrega:</FormTitle>
                  {siteConfig.configs.deliveryTypes.map((deliveryType) => (
                    <FormRow className="form-row" key={deliveryType.id}>
                      <>
                        <input
                          type="radio"
                          name="deliveryType"
                          id={'deliveryType-' + deliveryType.id}
                          value={deliveryType.id}
                          checked={selectedDeliveryType?.id === deliveryType.id}
                          onChange={() => {
                            setSelectedDeliveryType(deliveryType);
                          }}
                        />
                        <FormLabel htmlFor={'deliveryType-' + deliveryType.id}>
                          {deliveryType.name} - {numberToReal(deliveryType?.value)}
                        </FormLabel>
                      </>
                    </FormRow>
                  ))}
                </>
              )}
              <FormTitle primaryColor={primaryColor}>Endereço de Entrega:</FormTitle>
              <FormRow>
                <FormCol width="30%">
                  <FormLabel htmlFor="zip_code">CEP*</FormLabel>
                  <FormInput
                    ref={zipCodeRef}
                    type="text"
                    name="zip_code"
                    id="zip_code"
                    placeholder="CEP"
                    value={getValueFromState('zip_code')}
                  />
                  {formErrors.zip_code && <FormErrorSpan>{formErrors.zip_code}</FormErrorSpan>}
                </FormCol>
                <FormCol width="70%">
                  <FormLabel htmlFor="street">Endereço*</FormLabel>
                  <FormInput
                    type="text"
                    name="street"
                    id="street"
                    placeholder="Endereço"
                    value={getValueFromState('street')}
                    onChange={(e) => onChangeHandler('street', e.target.value)}
                  />
                  {formErrors.street && <FormErrorSpan>{formErrors.street}</FormErrorSpan>}
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol width="75%">
                  <FormLabel htmlFor="neighborhood">Bairro*</FormLabel>
                  <FormInput
                    type="text"
                    name="neighborhood"
                    id="neighborhood"
                    placeholder="Bairro"
                    value={getValueFromState('neighborhood')}
                    onChange={(e) => onChangeHandler('neighborhood', e.target.value)}
                  />
                  {formErrors.neighborhood && (
                    <FormErrorSpan>{formErrors.neighborhood}</FormErrorSpan>
                  )}
                </FormCol>
                <FormCol width="25%">
                  <FormLabel htmlFor="number">Número</FormLabel>
                  <FormInput
                    type="text"
                    name="number"
                    id="number"
                    placeholder="Número"
                    value={getValueFromState('number')}
                    onChange={(e) => onChangeHandler('number', e.target.value)}
                  />
                  {formErrors.number && <FormErrorSpan>{formErrors.number}</FormErrorSpan>}
                </FormCol>
              </FormRow>
              <FormRow>
                <FormCol width="50%">
                  <FormLabel htmlFor="uf">Estado*</FormLabel>
                  <FormSelect
                    name="uf"
                    id="uf"
                    value={getValueFromState('uf')}
                    onChange={(e) => onChangeHandler('uf', e.target.value)}
                  >
                    <option
                      value=""
                      selected={
                        getValueFromState('uf') === undefined || getValueFromState('uf') === ''
                          ? 'selected'
                          : 'false'
                      }
                    >
                      Selecione...
                    </option>
                    {listUFs.map((uf, i) => (
                      <option
                        key={i}
                        selected={getValueFromState('uf') === uf.id ? 'selected' : 'false'}
                        value={`${uf.id}`}
                      >
                        {uf.name}
                      </option>
                    ))}
                  </FormSelect>
                  {formErrors.uf && <FormErrorSpan>{formErrors.uf}</FormErrorSpan>}
                </FormCol>
                <FormCol width="50%">
                  <FormLabel htmlFor="city">Cidade*</FormLabel>
                  <FormSelect
                    name="city"
                    id="city"
                    value={getValueFromState('city')}
                    onChange={(e) => onChangeHandler('city', e.target.value)}
                  >
                    <option
                      value=""
                      selected={
                        getValueFromState('city') === undefined || getValueFromState('city') === ''
                          ? 'selected'
                          : 'false'
                      }
                    >
                      Selecione...
                    </option>
                    {listCities.map((city, i) => (
                      <option
                        key={i}
                        selected={getValueFromState('city') === city.name ? 'selected' : 'false'}
                        value={`${city.name}`}
                      >
                        {city.name}
                      </option>
                    ))}
                  </FormSelect>
                  {formErrors.city && <FormErrorSpan>{formErrors.city}</FormErrorSpan>}
                </FormCol>
              </FormRow>
              <FormLabel htmlFor="complement">Complemento</FormLabel>
              <FormInput
                type="text"
                name="complement"
                id="complement"
                placeholder="Complemento"
                value={getValueFromState('complement')}
                onChange={(e) => onChangeHandler('complement', e.target.value)}
              />
              {formErrors.complement && <FormErrorSpan>{formErrors.complement}</FormErrorSpan>}
            </>
          )}
        </>
      )}
      <FormRow style={{ marginTop: 'auto', justifyContent: 'end' }}>
        <FormCol width="25%">
          <ButtonRounded
            onClick={() => (authCustomer ? setProgress(2) : setProgress(3))}
            auxColor={auxColor}
            primaryColor={primaryColor}
            isDarkTheme={isDarkTheme}
          >
            Voltar
          </ButtonRounded>
        </FormCol>
        <FormCol width="25%">
          <ButtonRounded
            onClick={submitHandler}
            auxColor={auxColor}
            primaryColor={primaryColor}
            isDarkTheme={isDarkTheme}
          >
            Confirmar
          </ButtonRounded>
        </FormCol>
      </FormRow>
    </CheckoutAddressForm>
  );
};
