import { useContext, useMemo } from 'react';
import SiteContext from '../contexts/SiteConfigContext';
import Helpers from '../utils/helpers';

const useColors = () => {
  const { siteConfig } = useContext(SiteContext);

  const primaryColor = useMemo(
    () => siteConfig?.store?.configs?.main_color || '#ffffff',
    [siteConfig?.store?.configs?.main_color]
  );

  const primaryColorBalance = Helpers.getBalanceByColor(primaryColor);

  const isDarkTheme = primaryColorBalance < 240;
  const isLightTheme = primaryColorBalance > 150;

  const auxColor = isDarkTheme ? '#ffffff' : '#000000';

  return { primaryColor, primaryColorBalance, isDarkTheme, isLightTheme, auxColor };
};

export default useColors;
