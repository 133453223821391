import React from 'react';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import { ModalProvider } from 'styled-react-modal';
import { AuthContextProvider } from './contexts/AuthContext';
import { SiteConfigProvider } from './contexts/SiteConfigContext';

import App from './App';

// import reportWebVitals from './reportWebVitals';

import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import { CheckoutContextProvider } from './contexts/CheckoutContext';

const rootEl = document.getElementById('root');

if (!rootEl) throw new Error('Root container not found');

const root = createRoot(rootEl);

root.render(
  <React.StrictMode>
    <SiteConfigProvider>
      <AuthContextProvider>
        <CheckoutContextProvider>
          <ModalProvider>
            <ToastContainer />
            <App />
          </ModalProvider>
        </CheckoutContextProvider>
      </AuthContextProvider>
    </SiteConfigProvider>
  </React.StrictMode>
);

// reportWebVitals();
