import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { ProductsSearchProvider } from '../contexts/ProductsSearchContext';
import Home from '../pages/Home';
import Product from '../pages/Product';
import Cart from '../pages/Cart';
import Login from '../pages/Login';
import Register from '../pages/Register';
import Profile from '../pages/Profile';
import Sales from '../pages/Sales';
import SaleDetail from '../pages/SaleDetail';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ProductsSearchProvider>
        <Home />
      </ProductsSearchProvider>
    ),
  },
  {
    path: '/carrinho',
    element: <Cart />,
  },
  {
    path: '/cadastre-se',
    element: <Register />,
  },
  {
    path: '/entrar',
    element: <Login />,
  },
  {
    path: '/meu-perfil',
    element: <Profile />,
  },
  {
    path: '/meus-pedidos',
    element: <Sales />,
  },
  {
    path: '/meus-pedidos/:orderId',
    element: <SaleDetail />,
  },
  {
    path: '/produtos/:productId',
    element: <Product />,
  },
]);

export default router;
