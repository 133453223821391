import styled from 'styled-components';

export const LoadingContainer = styled.div`
  background-color: #f4f4f4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.4rem;
  gap: 1rem;
`;
