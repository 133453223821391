import React, { useState, useContext, useEffect } from 'react';
import { checkIfUseMercadoPago } from '../../services/Auth';
/* import { useIMask } from 'react-imask';
import * as z from 'zod'; */

// import { useNavigate } from 'react-router-dom';
import {
  ButtonRounded,
  CheckoutDetails,
  CheckoutPaymentTypeForm,
  FormTitle,
  FormLabel,
  FormRow,
  FormCol,
  CheckoutPaymentTypeColumn,
  ProcessedByAdvice,
  CheckoutPaymentMessageError,
  PaymentDescription,
  /* CheckoutCardDataForm,
  FormInput,
  FormErrorSpan,
  QRCodePlaceholder,
  PixCodeTextArea,
  PixAdvice, */
} from './styles';
import useColors from '../../hooks/useColors';
import { NavPills } from './NavPills';
import mercadopagopng from '../../resources/img/mercadopago.png';
import AuthContext from '../../contexts/AuthContext';
import SiteContext from '../../contexts/SiteConfigContext';
import { listPaymentMethods } from '../../services/Site';

export const CheckoutPaymentStep = ({
  maxProgress,
  progress,
  setProgress,
  formsState,
  setFormState,
}) => {
  /* const formRef = useRef(null);
  const { ref: cardNumberRef } = useIMask(
    { mask: '0000 0000 0000 0000' },
    { onAccept: (value) => onChangeHandler('cardNumber', value) }
  );
  const { ref: expiracyRef } = useIMask(
    { mask: '00/00' },
    { onAccept: (value) => onChangeHandler('expiration', value) }
  );
  const { ref: cvvRef } = useIMask(
    { mask: '0000' },
    { onAccept: (value) => onChangeHandler('cvv', value) }
  ); */

  // const navigate = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState(
    formsState.payment ? formsState.payment['paymentMethod'] : ''
  );
  const [paymentOptions, setPaymentOptions] = useState(null);
  const [showMessageError, setShowMessageError] = useState(false);
  const [useMercadoPago, setUseMercadoPago] = useState(false);
  const { primaryColor, auxColor, isDarkTheme } = useColors();
  const { siteConfig } = useContext(SiteContext);
  const { authCustomer } = useContext(AuthContext);
  const { subdomain } = useContext(SiteContext);
  /*
  const [formErrors, setFormErrors] = useState({});

  const schema = z.object({
    cardNumber: z
      .string()
      .min(1, 'Número do cartão é obrigatório')
      .refine(
        (value) => /^[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}$/.test(value),
        'Número do cartão inválido'
      ),
    name: z.string().min(1, 'Nome Impresso é obrigatório'),
    expiration: z
      .string()
      .min(1, 'Validade é obrigatória')
      .refine((value) => /^[0-9]{2}\/[0-9]{2}$/.test(value), 'Validade inválido'),
    cvv: z
      .string()
      .min(1, 'CVV é obrigatório')
      .refine((value) => /^[0-9]{3, 4}$/.test(value), 'CVV inválido'),
  }); */

  useEffect(() => {
    const checkMercadoPago = async () => {
      const data = await checkIfUseMercadoPago(subdomain);
      setUseMercadoPago(data);
    };

    checkMercadoPago();
  }, [subdomain]);

  const submitHandler = () => {
    if (paymentMethod) {
      if (authCustomer) {
        if (!siteConfig.configs.allowDelivery && !siteConfig.configs.allowPickup) {
          setProgress(5);
        } else {
          setProgress(4);
        }
      } else {
        setProgress(3);
      }
    } else {
      setShowMessageError(true);
    }
  };

  const onChangeHandler = (name, value) =>
    setFormState((state) => ({ ...state, payment: { ...state.payment, [name]: value } }));

  useEffect(() => {
    if (siteConfig.configs.paymentOptions) {
      setPaymentOptions(siteConfig.configs.paymentOptions);
    }
  }, [siteConfig.configs.paymentOptions]);

  return (
    <CheckoutDetails>
      <NavPills
        maxProgress={maxProgress}
        progress={progress}
        setProgress={setProgress}
        formsState={formsState}
      />
      <FormTitle primaryColor={primaryColor}>Método de Pagamento</FormTitle>
      {showMessageError && (
        <CheckoutPaymentMessageError>
          Você deve selecionar um método de pagamento para continuar!
        </CheckoutPaymentMessageError>
      )}
      <CheckoutPaymentTypeForm>
        {useMercadoPago && (
          <CheckoutPaymentTypeColumn primaryColor={primaryColor}>
            <p>Pagamento Online</p>
            <div>
              <input
                type="radio"
                name="paymentMethod"
                id="mercadopagoPaymentMethod"
                value="mercadopago"
                checked={paymentMethod.slug === 'mercadopago'}
                onChange={() => {
                  setPaymentMethod({ name: 'Mercado pago', slug: 'mercadopago' });
                  onChangeHandler('paymentMethod', { name: 'Mercado pago', slug: 'mercadopago' });
                }}
              />
              <FormLabel htmlFor="mercadopagoPaymentMethod">Pagamento online</FormLabel>
              <ProcessedByAdvice>
                Processado por <br />
                <img
                  src={mercadopagopng}
                  alt="Icone mercado pago"
                  style={{ width: '3.5rem', marginLeft: '0.5rem' }}
                />
              </ProcessedByAdvice>
            </div>
          </CheckoutPaymentTypeColumn>
        )}
        {!useMercadoPago && (
          <CheckoutPaymentTypeColumn primaryColor={primaryColor}>
            <p>Selecione a forma de pagamento</p>
          </CheckoutPaymentTypeColumn>
        )}
        <CheckoutPaymentTypeColumn primaryColor={primaryColor}>
          <p>Pagamento na Entrega/Retirada</p>
          {paymentOptions &&
            paymentOptions.map((value, index) => {
              return (
                <>
                  <div key={index}>
                    <input
                      type="radio"
                      name="paymentMethod"
                      id="creditPaymentMethod"
                      value={value.slug}
                      checked={paymentMethod.slug === value.slug}
                      onChange={() => {
                        setPaymentMethod({
                          name: value.name,
                          slug: value.slug,
                          description: value.description,
                        });
                        onChangeHandler('paymentMethod', {
                          name: value.name,
                          slug: value.slug,
                          description: value.description,
                        });
                      }}
                    />
                    <FormLabel htmlFor="creditPaymentMethod">{value.name}</FormLabel>
                  </div>
                  {value.description && (
                    <PaymentDescription>{value.description}</PaymentDescription>
                  )}
                </>
              );
            })}
        </CheckoutPaymentTypeColumn>
      </CheckoutPaymentTypeForm>

      {/* {paymentMethod === 'cartao' && (
        <>
          <FormTitle>Dados do Cartão</FormTitle>
          <CheckoutCardDataForm ref={formRef}>
            <FormRow>
              <FormCol width="100%">
                <FormLabel htmlFor="cardNumber">Número do Cartão*</FormLabel>
                <FormInput
                  ref={cardNumberRef}
                  type="text"
                  name="cardNumber"
                  id="cardNumber"
                  placeholder="0000 0000 0000 0000"
                  value={getValueFromState('cardNumber')}
                />
                {formErrors.cardNumber && <FormErrorSpan>{formErrors.cardNumber}</FormErrorSpan>}
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol width="100%">
                <FormLabel htmlFor="name">Nome Impresso</FormLabel>
                <FormInput
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Nome"
                  value={getValueFromState('name')}
                  onChange={(e) => onChangeHandler('name', e.target.value)}
                />
                {formErrors.name && <FormErrorSpan>{formErrors.name}</FormErrorSpan>}
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol width="50%">
                <FormLabel htmlFor="expiration">Validade*</FormLabel>
                <FormInput
                  ref={expiracyRef}
                  type="text"
                  name="expiration"
                  id="expiration"
                  placeholder="MM/AA"
                  value={getValueFromState('expiration')}
                />
                {formErrors.expiration && <FormErrorSpan>{formErrors.expiration}</FormErrorSpan>}
              </FormCol>
              <FormCol width="50%">
                <FormLabel htmlFor="cvv">CVV*</FormLabel>
                <FormInput
                  ref={cvvRef}
                  type="text"
                  name="cvv"
                  id="cvv"
                  placeholder="CVV"
                  value={getValueFromState('cvv')}
                />
                {formErrors.cvv && <FormErrorSpan>{formErrors.cvv}</FormErrorSpan>}
              </FormCol>
            </FormRow>
          </CheckoutCardDataForm>
        </>
      )}

      {paymentMethod === 'pix' && (
        <>
          <FormTitle>QRCode Pix</FormTitle>
          <CheckoutCardDataForm>
            <FormRow>
              {/* <img alt="pix qrcode" src /> * /}
              <QRCodePlaceholder></QRCodePlaceholder>
              <FormCol width="100%" style={{ marginTop: '1rem' }}>
                <PixCodeTextArea></PixCodeTextArea>
                <ButtonRounded
                  type="button"
                  style={{ borderRadius: '0.7rem', marginTop: '1.5rem' }}
                  auxColor={auxColor}
                  primaryColor={primaryColor}
                  isDarkTheme={isDarkTheme}
                >
                  Copiar código PIX
                </ButtonRounded>
                <PixAdvice
                  style={{ marginTop: '1.5rem' }}
                  primaryColor={primaryColor}
                  isDarkTheme={isDarkTheme}
                >
                  <span>1</span>
                  <div>
                    Abra o app ou banco de sua preferência. Escolha a opção pagar com Pix "copia e
                    cola", ou código QR.
                  </div>
                </PixAdvice>
                <PixAdvice primaryColor={primaryColor} isDarkTheme={isDarkTheme}>
                  <span>2</span>
                  <div>
                    Copie e cole o código, ou escaneie o código QR com a câmera do seu celular.
                    Confira todas as informações e autorize o pagamento.
                  </div>
                </PixAdvice>
                <PixAdvice primaryColor={primaryColor} isDarkTheme={isDarkTheme}>
                  <span>3</span>
                  <div>Você vai receber a confirmação do pagamento por e-mail ou WhatsApp.</div>
                </PixAdvice>
              </FormCol>
            </FormRow>
          </CheckoutCardDataForm>
        </>
      )} */}

      <FormRow style={{ marginTop: 'auto', justifyContent: 'end' }}>
        <FormCol width="25%">
          <ButtonRounded
            onClick={() => setProgress(1)}
            auxColor={auxColor}
            primaryColor={primaryColor}
            isDarkTheme={isDarkTheme}
          >
            Voltar
          </ButtonRounded>
        </FormCol>
        <FormCol width="25%">
          <ButtonRounded
            onClick={submitHandler}
            auxColor={auxColor}
            primaryColor={primaryColor}
            isDarkTheme={isDarkTheme}
          >
            Confirmar
          </ButtonRounded>
        </FormCol>
      </FormRow>
    </CheckoutDetails>
  );
};
