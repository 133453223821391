import api from '../api';

export async function finishCart(
  store_id,
  products,
  address,
  customer,
  payment,
  notes,
  orderType = null,
  deliveryType = null
) {
  try {
    const { data } = await api.post('store/checkout', {
      store_id: store_id,
      products: products,
      customer: customer,
      address: address,
      payment: payment.paymentMethod.slug,
      notes: notes,
      order_type: orderType,
      delivery_type_id: deliveryType?.id,
    });

    return {
      message: 'Compra processada com sucesso! Carrinho finalizado.',
      success: true,
      data,
    };
  } catch (error) {
    return {
      message: 'Erro ao finalizar carrinho. Tente mais tarde.',
      error: error,
      success: false,
    };
  }
}
