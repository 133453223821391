import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Modal from 'styled-react-modal';

export const CartNotesInput = styled.textarea`
  padding: 0.5rem 1rem;
  margin: 0.5rem 1rem;
  height: 10rem;
  border-radius: 0.2rem;
  border: 1px solid ${(props) => (props.isDarkTheme ? props.primaryColor : props.auxColor)};
  outline: 0;
`;

export const ConfirmModal = Modal.styled`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 0 solid #f6f6f6;
  border-radius: .25rem;
  width: 80%;
  @media (min-width: 768px) {
    width: 30%;
  }

  .modal-header {
    align-items: flex-start;
    border-bottom: 1px solid #eff2f7;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
    display: flex;
    justify-content: space-between;
    padding: 1rem;

    .close-button {
      margin: -1rem -1rem -1rem auto;
      padding: 0.75rem 1rem;
      background: none;
      border: none;
      cursor: pointer;
      font-size: 1.5rem;
    }
  }

  .modal-footer {
    display: flex;
    justify-content: end;
    gap: 1rem;
    padding: 1rem;
    button {
      width: 30%;
      padding: 0.25rem 1rem;
      font-size: 1rem;
      border: none;
      color: white;
      cursor: pointer;
      @media (max-width: 992px) {
        width: calc(50% - 0.5rem);
      }
    }
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 0.5rem;
      button {
        width: 100%;
      }
    }
  }
`;

export const ButtonRounded = styled.button`
  width: 100%;
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.7rem;
  border: none;
  cursor: pointer;
  transition: box-shadow 0.3s ease;

  background-color: ${(props) => (props.isDarkTheme ? props.primaryColor : props.auxColor)};
  color: ${(props) => (props.isDarkTheme ? props.auxColor : props.primaryColor)};
  border: 1px solid ${(props) => (props.isDarkTheme ? props.auxColor : props.primaryColor)};

  margin: 1rem 0 0 auto;
  @media (min-width: 768px) {
    margin: 1.2rem 0 0 auto;
  }
`;

export const PixAdvice = styled.p`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  color: ${(props) => (props.isDarkTheme ? props.primaryColor : 'black')};

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 1.2rem;
    border: 2px solid ${(props) => (props.isDarkTheme ? props.primaryColor : 'black')};
    height: 2.5rem;
    width: 2.5rem;
    aspect-ratio: 1;
    border-radius: 9999px;
  }
`;

export const NavPillsLi = styled.li`
  z-index: 2;
  button {
    background: ${(props) => (props.isDarkTheme ? props.auxColor : props.primaryColor)};
    border: 1px solid ${(props) => (props.isDarkTheme ? props.primaryColor : props.auxColor)};
    color: ${(props) => (props.isDarkTheme ? props.primaryColor : props.auxColor)};
    border-radius: 999px;
    width: 2rem;
    height: 2rem;
    text-align: center;
    cursor: pointer;
  }
`;

export const NavPillsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;

  margin-bottom: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    margin: 1.5rem 0;
  }

  h1 {
    font-weight: 500;
    margin-bottom: 1rem;
  }
`;

export const NavPillsWrapper = styled.nav`
  display: flex;
  width: 100%;

  @media (min-width: 768px) {
    width: 50%;
  }

  position: relative;

  ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    list-style: none;
  }
`;

export const ProgressBar = styled.div`
  background-color: ${(props) => (props.isDarkTheme ? props.primaryColor : props.auxColor)};
  position: absolute;
  left: 0;
  top: 1rem;
  transition: all 0.4s ease;

  height: ${(props) => (!props.isDarkTheme ? '1px' : '1px')};
  width: 100%;

  ${(props) => (!props.isDarkTheme ? `border: 1px solid ${props.auxColor};` : '')}
  overflow: hidden;

  div {
    width: ${(props) => Math.min(100, (props.progress - 1) * 25)}%;
    height: 2px;
    background-color: #ffffff;
  }
`;

export const SummaryHeader = styled.div`
  grid-column: 1 / 3;
  grid-row: 1;

  display: flex;
  justify-content: space-between;
  align-items: center;

  max-height: 6rem;

  h1 {
    font-size: 2rem;
    font-weight: 500;
    padding: 1rem 3rem;
  }

  @media (max-width: 576px) {
    flex-direction: column;
    max-height: 10rem;

    h1 {
      padding: 1rem 0 0;
    }
  }
`;

export const ProductList = styled.div`
  padding: 1.5rem 0;

  @media (min-width: 768px) {
    /* padding: 1.5rem 15rem; */
  }

  ul.list-products {
    list-style: none;

    li {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1.5rem;

      .product-image {
        border: none;
        aspect-ratio: 1;
        width: 4rem;
        height: 4rem;
        @media (max-width: 768px) {
          width: 30cqw;
          height: 30cqw;
        }
        border-radius: 5px;
        font-size: 1.5rem;
      }

      div.product-image {
        padding: 1rem;
        background-color: ${(props) => props.primaryColor};
        color: #faab2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        text-align: center;
      }
    }

    li.list-headers {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 0 3rem;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .empty-cart {
    text-align: center;
    padding: 40px 20px;

    h4 {
      font-weight: 500;
      color: ${({ primaryColor, auxColor, isDarkTheme }) =>
        isDarkTheme ? primaryColor : auxColor};
    }
  }

  .product-list-total {
    border-top: 2px solid #e0e0e0;
    padding-top: 1rem;
    text-align: right;
    font-weight: 600;
  }
`;

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.1rem;
  font-weight: 600;
  font-size: 12px;
  background: transparent;
  border: none;
  cursor: pointer;
  margin-bottom: 0.3rem;
`;

export const IconButtonStyle = styled.div`
  max-width: 30%;
  align-items: center;
  gap: 0.1rem;
  font-weight: 600;
  font-size: 12px;
  background: transparent;
  border: none;
  cursor: pointer;
  margin-bottom: 0.3rem;

  span {
    display: block;
    word-wrap: break-word;
  }
`;

export const CheckoutDetails = styled.div`
  padding: 1.5rem 0;

  @media (min-width: 768px) {
    /* padding: 1.5rem 15rem; */
  }

  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

export const CheckoutContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CheckoutContactForm = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 1.5em;
  border-right: 1px solid #ccc;

  @media (max-width: 768px) {
    padding-right: 0;
    border-right: 0px;
  }
`;

export const CheckoutContactLogin = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 1.5em;

  @media (max-width: 768px) {
    padding-left: 0;
    margin-top: 1em;
  }
`;

export const CheckoutContactAuth = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const CheckoutPaymentMessageError = styled.span`
  padding-top: 10px;
  padding-bottom: 10px;
  color: red;
  font-weight: bold;
  font-size: 14px;
`;

export const CheckoutPaymentTypeForm = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const CheckoutPaymentTypeColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 0.5rem;

  p {
    margin-bottom: 0.5rem;
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }

  input[type='radio'] {
    margin-bottom: 0.25rem;
    margin-right: 0.5rem;
    accent-color: ${({ primaryColor, auxColor, isDarkTheme }) =>
      isDarkTheme ? primaryColor : auxColor};
  }
`;

export const ProcessedByAdvice = styled.p`
  margin-left: auto;
  margin-top: -0.5rem;
  font-size: 0.7rem;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const CheckoutCardDataForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const CheckoutAddressForm = styled.form`
  padding: 1.5rem 0;

  @media (min-width: 768px) {
    /* padding: 1.5rem 15rem; */
  }

  display: flex;
  flex-direction: column;
`;

export const CheckoutFormTitle = styled.h3`
  font-size: 1.1rem;
  font-weight: 600;
  color: ${({ primaryColor, auxColor, isDarkTheme }) => (isDarkTheme ? primaryColor : auxColor)};
`;

export const CheckoutFormSpan = styled.span`
  font-size: 0.7rem;
  font-weight: 600;
  color: #aaa;
  padding-top: 1em;
  padding-bottom: 1em;
`;

export const FormTitle = styled.h1`
  font-size: 1.1rem;
  font-weight: 600;
  color: ${({ primaryColor, auxColor, isDarkTheme }) => (isDarkTheme ? primaryColor : auxColor)};
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const FormLabel = styled.label`
  margin-bottom: 0.25rem;
  font-size: 0.9rem;
  font-weight: 400;
`;

export const FormInput = styled.input`
  border: 1px solid #ccc;
  border-radius: 8px;
  height: 1.3rem;
  padding: 1.3rem 0.7rem;
  margin-bottom: 0.75rem;
  font-size: 1rem;
  &:focus {
    outline: 1px solid gray;
  }
`;

export const FormSelect = styled.select`
  border: 1px solid #ccc;
  border-radius: 8px;
  height: 2.6rem;
  padding: 0rem 0.7rem;
  margin-bottom: 0.75rem;
  font-size: 1rem;
`;

export const FormRow = styled.div`
  display: flex;
  @media (min-width: 768px) {
    gap: 1rem;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FormCol = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    width: ${(props) => (props.width === '100%' ? props.width : `calc(${props.width} - 0.5rem)`)};
  }
`;

export const P = styled.p`
  font-size: 0.9rem;
`;

export const ProductName = styled.span`
  font-size: 0.9rem;
  color: #000;
  font-weight: bold;
`;

export const QuantityInput = styled.p`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1.1rem;
  button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: ${(props) => (!props.isDarkTheme ? props.auxColor : props.primaryColor)};
    font-size: 1.5rem;
    margin: 0 0.4rem;
  }
`;

export const PixCodeTextArea = styled.textarea`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0.5rem 0.2rem 0.9rem 0.5rem;
  &:focus {
    outline: 1px solid gray;
  }
`;

export const CancelButton = styled.button`
  background-color: #e0e0e0;
  cursor: pointer;
  border: 2px solid white;
  border-radius: 99999px;
  height: 1.6rem;
  width: 1.6rem;
`;

export const ConfirmationWrapper = styled.div`
  margin: 5rem 2rem;

  text-align: center;

  img {
    width: 4rem;
  }
`;

export const FormErrorSpan = styled.span`
  font-size: 0.75rem;
  color: #eb5757;
  margin-top: -0.55rem;
  margin-bottom: 0.55rem;
`;

export const BackLink = styled(Link)`
  margin-bottom: 1.2rem;
  text-decoration: none;
  width: 7rem;
  font-size: 1.1rem;

  color: ${(props) => (props.isDarkTheme ? props.primaryColor : props.auxColor)};
  display: inline-block;
  font-weight: 600;
`;

export const CardProduct = styled.div`
  position: relative;
  background-color: white;
  border: 1px solid #f0f0f0;
  border-radius: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  width: 100%;
  & > div {
    display: flex;
    align-items: center;
    gap: 1rem;
    width: 30%;
  }
  @media (max-width: 768px) {
    display: none;
  }
  .price {
    display: flex;
    justify-content: flex-start;
    margin-right: 1rem;
    width: 30%;
  }
  .total {
    display: flex;
    justify-content: end;
    width: 30%;
  }
  button.cancel-button {
    position: absolute;
    right: -0.75rem;
    top: calc(50% - 0.6rem);
  }
`;

export const MobileCardProduct = styled.div`
  position: relative;
  display: flex;
  align-items: start;
  gap: 1rem;
  width: 100%;
  background-color: white;
  padding: 1rem;
  & > div {
    justify-content: space-between;
    align-items: start;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .price {
      font-size: 1.25rem;
    }
  }
  @media (min-width: 768px) {
    display: none;
  }
  button.cancel-button {
    position: absolute;
    right: -0.75rem;
    top: calc(50% - 0.8rem);
  }
`;

export const QRCodePlaceholder = styled.div`
  background-image: url(https://boofcv.org/images/thumb/3/35/Example_rendered_qrcode.png/400px-Example_rendered_qrcode.png);
  background-size: cover;
  aspect-ratio: 1;
  width: 50cqw;
  @media (min-width: 768px) {
    width: 20cqw;
  }
  margin: auto;
  margin-top: 1rem;
`;

export const ConfirmationStep = styled.div`
  padding: 1.5rem 0;

  .confirmation-columns {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    /* padding: 1.5rem 15rem; */

    .confirmation-columns {
      flex-direction: row;
    }
  }

  ul.list-products {
    list-style: none;

    li {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1.5rem;

      .product-card {
        background-color: white;
        border: 1px solid #f0f0f0;
        border-radius: 1rem;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        width: 100%;

        div > span {
          position: absolute;
          right: -0.5rem;
          top: -0.4rem;
          background-color: ${({ primaryColor, auxColor, isDarkTheme }) =>
            isDarkTheme ? primaryColor : auxColor};
          color: ${({ primaryColor, auxColor, isDarkTheme }) =>
            isDarkTheme ? auxColor : primaryColor};
          font-size: 0.9rem;
          text-align: center;
          height: 1.5rem;
          width: 1.5rem;
          border-radius: 99999px;
        }
      }

      .product-image {
        border: none;
        aspect-ratio: 1;
        width: 4rem;
        height: 4rem;
        @media (max-width: 768px) {
          width: 30cqw;
          height: 30cqw;
        }
        border-radius: 5px;
        font-size: 1.5rem;
      }

      div.product-image {
        padding: 1rem;
        background-color: ${(props) => props.primaryColor};
        color: #faab2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .confirmation-sections > div {
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid #ddd;

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }

      span {
        color: ${(props) => (props.primaryColor === '#FFFFFF' ? props.primaryColor : 'black')};
      }

      h1 {
        display: flex;
        justify-content: space-between;
        font-size: 1rem;
        color: ${({ primaryColor, auxColor, isDarkTheme }) =>
          isDarkTheme ? primaryColor : auxColor};
        margin-bottom: 0.25rem;

        .edit-button {
          margin-left: auto;
          border: none;
          background: none;
          font-size: 0.9rem;
          cursor: pointer;
          img {
            margin-bottom: -0.25rem;
          }
        }
      }
    }
  }
`;

export const FinishCartContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 1px 0px 20px #ccc;
  padding-bottom: 40px;
`;

export const FinishCartContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
`;

export const FinishCartContentTitle = styled.h1`
  font-size: 24px;
  color: ${({ primaryColor, auxColor, isDarkTheme }) => (isDarkTheme ? primaryColor : auxColor)};
`;

export const FinishCartContentOrderNumber = styled.span`
  font-size: 18px;
  color: #333;
`;

export const FinishCartContactButton = styled.button`
  padding: 10px 20px;
  background: ${(props) => (props.isDarkTheme ? props.auxColor : props.primaryColor)};
  color: ${(props) => (props.isDarkTheme ? props.primaryColor : props.auxColor)};
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const FinishCartOrderDetails = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  background-color: #fff;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FinishCartItemList = styled.div`
  border-bottom: 1px solid #ccc;
  flex: 1;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

export const FinishCartItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .product-image {
    border: none;
    aspect-ratio: 1;
    width: 60px;
    height: 60px;
    @media (max-width: 768px) {
      width: 60px;
      height: 60px;
    }
    border-radius: 5px;
    font-size: 1.5rem;
  }

  div.product-image {
    padding: 1.2rem;
    background-color: ${(props) => props.primaryColor};
    color: #faab2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-align: center;
    margin-right: 10px;
  }
`;

export const FinishCartItemImage = styled.div`
  width: 60px;
  height: 60px;
  background: #e0e0e0;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

export const FinishCartItemInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FinishCartItemName = styled.span`
  font-size: 16px;
  color: #333;
`;

export const FinishCartItemPrice = styled.span`
  font-size: 14px;
  color: #999;
`;

export const FinishCartSummary = styled.div`
  flex: 2;
  padding: 20px;
  border-right: 1px solid #e0e0e0;

  @media (max-width: 768px) {
    border-right: 0px;
  }
`;

export const FinishCartSummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const FinishCartSummaryLabel = styled.span`
  font-size: 14px;
  color: #999;
`;

export const FinishCartSummaryValue = styled.span`
  font-size: 14px;
  color: #333;
`;

export const FinishCartTotal = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  color: #333;
`;

export const FinishCartDetailsSection = styled.div`
  flex: 1;
  padding: 20px;
`;

export const FinishCartDetailsRow = styled.div`
  margin-bottom: 10px;
  word-wrap: break-word;
`;

export const FinishCartDetailsLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${({ primaryColor, auxColor, isDarkTheme }) => (isDarkTheme ? primaryColor : auxColor)};
  margin-bottom: 5px;
`;

export const FinishCartDetailsValue = styled.span`
  font-size: 14px;
  color: #333;
`;

export const PaymentDescription = styled.p`
  font-size: 12px;
`;

export const PickupDescription = styled.p`
  font-size: 12px;
  margin-left: 25px;
  padding-bottom: 10px;
`;
