import { useContext } from 'react';
import useColors from '../../hooks/useColors';
import { numberToReal } from '../../utils/helpers';
import { NavPillsWrapper, NavPillsLi, ProgressBar, NavPillsContainer } from './styles';
import CheckoutContext from '../../contexts/CheckoutContext';

export const NavPills = ({ maxProgress, progress, setProgress, formsState }) => {
  const { primaryColor, isDarkTheme, auxColor } = useColors();

  const { checkoutProducts } = useContext(CheckoutContext);

  const checkoutSubtotal =
    checkoutProducts.reduce((acc, product) => acc + product.price * product.quantity, 0) +
    (formsState?.deliveryType ? parseFloat(formsState?.deliveryType.value) : 0);

  return (
    <NavPillsContainer>
      <h1>{numberToReal(checkoutSubtotal.toFixed(2))}</h1>
      <NavPillsWrapper>
        <ProgressBar
          progress={progress}
          primaryColor={primaryColor}
          isDarkTheme={isDarkTheme}
          auxColor={auxColor}
        >
          <div />
        </ProgressBar>
        <ul>
          <NavPillsLi
            active={progress >= 1}
            disabled={maxProgress < 1}
            primaryColor={primaryColor}
            isDarkTheme={isDarkTheme}
            auxColor={auxColor}
          >
            <button onClick={() => maxProgress >= 1 && setProgress(1)}>1</button>
          </NavPillsLi>
          <NavPillsLi
            active={progress >= 2}
            disabled={maxProgress < 2}
            primaryColor={primaryColor}
            isDarkTheme={isDarkTheme}
            auxColor={auxColor}
          >
            <button onClick={() => maxProgress >= 2 && setProgress(2)}>2</button>
          </NavPillsLi>
          <NavPillsLi
            active={progress >= 3}
            disabled={maxProgress < 3}
            primaryColor={primaryColor}
            isDarkTheme={isDarkTheme}
            auxColor={auxColor}
          >
            <button onClick={() => maxProgress >= 3 && setProgress(3)}>3</button>
          </NavPillsLi>
          <NavPillsLi
            active={progress >= 4}
            disabled={maxProgress < 4}
            primaryColor={primaryColor}
            isDarkTheme={isDarkTheme}
            auxColor={auxColor}
          >
            <button onClick={() => maxProgress >= 4 && setProgress(4)}>4</button>
          </NavPillsLi>
          <NavPillsLi
            active={progress >= 5}
            disabled={maxProgress < 5}
            primaryColor={primaryColor}
            isDarkTheme={isDarkTheme}
            auxColor={auxColor}
          >
            <button onClick={() => maxProgress >= 5 && setProgress(5)}>5</button>
          </NavPillsLi>
        </ul>
      </NavPillsWrapper>
    </NavPillsContainer>
  );
};
