import styled from 'styled-components';

export const ProductListItemWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 1rem 0.5rem;
  background-color: white;

  img {
    margin-bottom: 0;
    border-radius: 5px;
    aspect-ratio: 1;
    width: 100%;
  }

  .image-placeholder {
    border: ${(props) => (!props.isDarkTheme ? '1px solid #ccc' : 'none')};
    background-color: ${(props) => props.primaryColor};
    color: ${(props) => props.auxColor}b2;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    width: 100%;
    margin-bottom: 0.5rem;
    padding: 1rem;
    text-align: center;
    font-size: 3rem;
    border-radius: 5px;
  }

  a {
    text-decoration: none;
  }

  h2 {
    font-size: 1.1rem;
    font-weight: 700;
    color: #444;
    height: 55px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;

    /* margin-bottom: ${(props) =>
      props.siteConfig.products_listing === 1 ? '3rem' : '4.2rem'}; */
    @media (max-width: 576px) {
      margin-bottom: 0rem;
    }
  }

  h3 {
    /* position: absolute; */
    bottom: 2.2rem;
    font-size: 0.75rem;
    font-weight: 400;
    color: #222;
  }

  .text-error {
    ${(props) => (props.siteConfig.products_listing === 1 ? 'display: none;' : '')}
    // position: absolute;
    bottom: 5px;
    color: #d9534f;
    font-size: 0.8rem;
    // right: 5px;
  }

  h4 {
    font-weight: 700;
    font-size: 1.3rem;
    color: ${(props) => (props.isDarkTheme ? props.primaryColor : props.auxColor)};
    word-wrap: no-wrap;
    /* position: absolute; */
    bottom: 0.2rem;
  }
`;

export const PromotionalPriceView = styled.div`
  display: flex;
  flex-direction: column;

  span {
    display: flex;
    text-decoration: line-through;
    font-size: 0.8rem;
    color: #666;
  }

  h4 {
    display: flex;
    position: relative;
  }
`;

export const PromotionalTag = styled.div`
  position: absolute;
  color: #fff;
  background-color: red;
  padding: 5px 10px;
  border-radius: 5px;
  top: 25px;
  left: 15px;
`;
