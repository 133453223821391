import { useContext, useState } from 'react';
import useColors from '../../hooks/useColors';
import CheckoutContext from '../../contexts/CheckoutContext';
import { ConfirmModal, CartNotesInput } from './styles';

export const NotesModal = ({ show, setShow }) => {
  const { primaryColor, auxColor, isDarkTheme } = useColors();
  const [cartNoteInput, setCartNoteInput] = useState('');
  const { checkoutNote, setCheckoutNote } = useContext(CheckoutContext);
  return (
    <ConfirmModal isOpen={show}>
      <div className="modal-header">
        <h3>Observação</h3>
        <button className="close-button" onClick={() => setShow(false)}>
          ×
        </button>
      </div>
      <CartNotesInput
        primaryColor={primaryColor}
        auxColor={auxColor}
        isDarkTheme={isDarkTheme}
        defaultValue={checkoutNote}
        placeholder="Deixe sua observação aqui."
        onChange={(e) => {
          setCartNoteInput(e.target.value);
        }}
      />
      <div className="modal-footer">
        <button
          onClick={() => {
            setCheckoutNote(cartNoteInput);
            setShow(false);
          }}
          style={{
            backgroundColor: isDarkTheme ? primaryColor : auxColor,
            textAlign: 'center',
            padding: '5px',
          }}
        >
          {checkoutNote && checkoutNote.length > 0 ? 'Editar' : 'Adicionar'}
        </button>
      </div>
    </ConfirmModal>
  );
};
