import React from 'react';
import { LoadingContainer } from './styles';
import LoadingSpinner from '../../components/Loading/LoadingSpinner';

const Loading = () => {
  return (
    <LoadingContainer>
      <LoadingSpinner />
    </LoadingContainer>
  );
};

export default Loading;
