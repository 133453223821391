import { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SiteContext from '../../contexts/SiteConfigContext';
import useColors from '../../hooks/useColors';
import {
  Header,
  Img,
  Cart,
  CartItens,
  Login,
  Logo,
  HeaderItem,
  HeaderLink,
  HeaderIcon,
  ConfirmLogoutModal,
} from './styles';
import CheckoutContext from '../../contexts/CheckoutContext';
import AuthContext from '../../contexts/AuthContext';

const HeaderComponent = () => {
  const { siteConfig } = useContext(SiteContext);
  const { primaryColor, auxColor } = useColors();
  const { authCustomer, setAuthCustomerToken } = useContext(AuthContext);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const location = useLocation();

  const { checkoutProducts } = useContext(CheckoutContext);

  const processLogout = () => {
    setAuthCustomerToken(null);
    setShowLogoutModal(false);
    window.location.href = '/';
  };

  return (
    <>
      <Helmet>
        <title>{siteConfig?.store?.name ? siteConfig.store.name : 'Catálogo'}</title>
        <meta property="og:title" content={siteConfig?.store?.name || 'Catálogo'} />
        <meta
          property="og:description"
          content="Explore nossos produtos e descubra ofertas incríveis."
        />
        {siteConfig?.store?.logo && <meta property="og:image" content={siteConfig?.store?.logo} />}
      </Helmet>

      <Header primaryColor={primaryColor} auxColor={auxColor}>
        {siteConfig?.store?.logo && (
          <Logo>
            <Link to={'/'}>
              <Img src={siteConfig?.store?.logo} alt={'Catálogo'} />
            </Link>
          </Logo>
        )}

        {/* {location.pathname !== '/carrinho' ? (
        <HeaderItem>
          <HeaderLink to="/carrinho">
            <svg
              style={{ height: '2rem' }}
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
            >
              <path
                fill={auxColor}
                d="M17 18a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2c0-1.11.89-2 2-2M1 2h3.27l.94 2H20a1 1 0 0 1 1 1c0 .17-.05.34-.12.5l-3.58 6.47c-.34.61-1 1.03-1.75 1.03H8.1l-.9 1.63l-.03.12a.25.25 0 0 0 .25.25H19v2H7a2 2 0 0 1-2-2c0-.35.09-.68.24-.96l1.36-2.45L3 4H1zm6 16a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2c0-1.11.89-2 2-2m9-7l2.78-5H6.14l2.36 5z"
              />
            </svg>
            {checkoutProducts.length !== 0 && <CartItens>{checkoutProducts.length}</CartItens>}
          </HeaderLink>
        </HeaderItem>
      ) : (
        <HeaderItem></HeaderItem>
      )} */}

        <HeaderItem>
          {location.pathname !== '/carrinho' ? (
            <HeaderLink to="/carrinho">
              <svg
                style={{ height: '2rem' }}
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 26 26"
              >
                <path
                  fill={auxColor}
                  d="M17 18a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2c0-1.11.89-2 2-2M1 2h3.27l.94 2H20a1 1 0 0 1 1 1c0 .17-.05.34-.12.5l-3.58 6.47c-.34.61-1 1.03-1.75 1.03H8.1l-.9 1.63l-.03.12a.25.25 0 0 0 .25.25H19v2H7a2 2 0 0 1-2-2c0-.35.09-.68.24-.96l1.36-2.45L3 4H1zm6 16a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2c0-1.11.89-2 2-2m9-7l2.78-5H6.14l2.36 5z"
                />
              </svg>
              {checkoutProducts.length !== 0 && <CartItens>{checkoutProducts.length}</CartItens>}
            </HeaderLink>
          ) : (
            <></>
          )}
          {authCustomer && (
            <div
              style={{
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              <HeaderLink to="/meus-pedidos" style={{ textDecoration: 'none' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  fill={auxColor}
                  className="bi bi-person-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                  <path
                    fillRule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                  />
                </svg>
              </HeaderLink>
              <HeaderLink to="/meus-pedidos" style={{ textDecoration: 'none', marginTop: 14 }}>
                <p
                  style={{ color: auxColor, fontWeight: 'bold', fontSize: 12, marginTop: '-10px' }}
                >
                  Olá, {authCustomer.name}
                </p>
              </HeaderLink>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  setShowLogoutModal(true);
                }}
                style={{ textDecoration: 'none', fontSize: 12, color: auxColor }}
              >
                Sair
              </a>
            </div>
          )}
          {!authCustomer && (
            <HeaderLink to="/entrar" style={{ textDecoration: 'none', paddingTop: '0px' }}>
              <svg
                style={{ height: '2rem' }}
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                className="bi bi-box-arrow-in-right"
                viewBox="0 0 18 18"
                fill={auxColor}
              >
                <path
                  fillRule="evenodd"
                  d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0z"
                />
                <path
                  fillRule="evenodd"
                  d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
                />
              </svg>
              <p style={{ color: auxColor, fontWeight: 'bold', fontSize: 12, marginLeft: '5px' }}>
                Entrar
              </p>
            </HeaderLink>
          )}
        </HeaderItem>
        <ConfirmLogoutModal isOpen={showLogoutModal}>
          <div className="modal-header">
            <h3>Deseja realmente sair?</h3>
            <button className="close-button" onClick={() => setShowLogoutModal(false)}>
              ×
            </button>
          </div>
          <div className="modal-footer">
            <button
              onClick={() => setShowLogoutModal(false)}
              style={{
                backgroundColor: auxColor,
                color: primaryColor,
                padding: '5px',
              }}
            >
              Cancelar
            </button>
            <button
              onClick={() => {
                processLogout();
              }}
              style={{
                backgroundColor: auxColor,
                color: primaryColor,
                padding: '5px',
              }}
            >
              Sair
            </button>
          </div>
        </ConfirmLogoutModal>
      </Header>
    </>
  );
};

export default HeaderComponent;
