import React, { useEffect, createContext, useState, useCallback, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { productsList } from '../services/Products';
import SiteConfigContext from './SiteConfigContext';

export const ProductsSearchContext = createContext({});

export const ProductsSearchProvider = ({ children }) => {
  const { subdomain, SiteConfig } = useContext(SiteConfigContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [categoryFilter, setCategoryFilter] = useState(''); // ID da categoria
  const [categories, setCategories] = useState([]);
  const [query, setQuery] = useState(searchParams.get('query') || '');
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [orderby, setOrderby] = useState(searchParams.get('orderby') || null);
  const [page, setPage] = useState(searchParams.get('page') || 1);

  const getItemsPerPage = () => {
    if (window.innerWidth <= 768) {
      return 8; // Para telas menores
    }
    return 16; // Para telas maiores
  };

  const itemsPerPage = getItemsPerPage();

  // Função para listar categorias
  const listCategories = useCallback(async () => {
    setLoading(true);

    let categoriesSet = new Map();

    let loopLastPage = 2;

    for (let loopIndex = 1; loopIndex <= loopLastPage; loopIndex++) {
      const { data, success } = await productsList(subdomain, { page: loopIndex, orderby, query });

      if (!success) {
        toast.error('Erro ao carregar as categorias.');
        setLoading(false);
        return;
      }

      data.products.data.forEach((product) => {
        const category = product.category;
        if (!categoriesSet.has(category.id)) {
          categoriesSet.set(category.id, category); // Adiciona categoria única
        }
      });

      loopLastPage = data.products.last_page;
    }

    setCategories([...categoriesSet.values()]); // Converte Map para array
    setLoading(false);
  }, [subdomain, orderby, query]);

  // Função para listar produtos com filtro por categoria
  const listProducts = useCallback(async () => {
    setLoading(true);

    const productsListing = [];
    let loopLastPage = 2;

    for (let loopIndex = 1; loopIndex <= loopLastPage; loopIndex++) {
      const { data, success } = await productsList(subdomain, {
        page: loopIndex,
        orderby,
        query,
        categoryId: categoryFilter || undefined, // Aqui estamos enviando corretamente o categoryId
      });

      if (!success) {
        toast.error('Erro ao carregar a lista de produtos.');
        setLoading(false);
        return;
      }

      const filteredProducts = data.products.data.filter((product) => {
        return SiteConfig?.store?.configs?.products_listing !== 3 || product.amount > 0;
      });

      productsListing.push(...filteredProducts);
      loopLastPage = data.products.last_page;
    }

    setProducts(productsListing);
    setLoading(false);
  }, [subdomain, SiteConfig, orderby, query, categoryFilter]);

  useEffect(() => {
    listCategories();
  }, [listCategories]);

  useEffect(() => {
    const params = {};

    if (query) params.query = query;
    if (orderby) params.orderby = orderby;

    if (page && page !== 1 && !categoryFilter) {
      params.page = page;
    }

    setSearchParams(params);
  }, [searchParams, setSearchParams, query, orderby, page, categoryFilter]);

  // Recarrega produtos quando o filtro de categoria mudar
  useEffect(() => {
    listProducts();
    setPage(1);
  }, [listProducts, categoryFilter]); // Atualiza sempre que `categoryFilter` mudar

  return (
    <ProductsSearchContext.Provider
      value={{
        loading,
        setQuery,
        query,
        categories,
        setCategoryFilter, // Função para atualizar filtro
        categoryFilter, // ID da categoria selecionada
        lastPage: Math.ceil(products.length / itemsPerPage),
        products: products.slice(itemsPerPage * (page - 1), itemsPerPage * page),
        page,
        setPage,
        setOrderby,
        orderby,
      }}
    >
      {children}
    </ProductsSearchContext.Provider>
  );
};

export default ProductsSearchContext;
