import React, { useState, useRef, useContext } from 'react';
import { useIMask } from 'react-imask';
import * as z from 'zod';

// import { useNavigate } from 'react-router-dom';
import {
  ButtonRounded,
  CheckoutDetails,
  CheckoutFormTitle,
  FormInput,
  FormLabel,
  CheckoutContactForm,
  FormRow,
  FormCol,
  FormErrorSpan,
  CheckoutContactLogin,
  CheckoutContactContainer,
  CheckoutContactAuth,
  CheckoutFormSpan,
} from './styles';
import useColors from '../../hooks/useColors';
import { NavPills } from './NavPills';
import LoadingSpinner from '../Loading/LoadingSpinner';
import SiteContext from '../../contexts/SiteConfigContext';
import AuthContext from '../../contexts/AuthContext';
import {
  checkIfCustomerExists,
  processAuthCustomer,
  registerCustomer,
  registerQuickBuyCustomer,
} from '../../services/Auth';

const schemaQuickBuy = z.object({
  nameQuickBuy: z.string().min(1, 'Nome é obrigatório!'),
  telephoneQuickBuy: z
    .string()
    .min(1, 'Telefone é obrigatório!')
    .refine(
      (value) => /^\([1-9]{2}\) [9]{0,1}[0-9]{4}-[0-9]{4}$/.test(value),
      'Telefone inválido!'
    ),
  emailQuickBuy: z.string().min(1, 'E-mail é obrigatório!').email('E-mail inválido!'),
});

const schemaCheckEmail = z.object({
  email: z.string().min(1, 'E-mail é obrigatório!').email('E-mail inválido!'),
});

const schemaLogin = z.object({
  email: z.string().min(1, 'E-mail é obrigatório!').email('E-mail inválido!'),
  password: z.string().min(6, 'Informe uma senha!'),
});

const schemaRegister = z
  .object({
    name: z.string().min(1, 'Nome é obrigatório!'),
    telephone: z
      .string()
      .min(1, 'Telefone é obrigatório!')
      .refine(
        (value) => /^\([1-9]{2}\) [9]{0,1}[0-9]{4}-[0-9]{4}$/.test(value),
        'Telefone inválido!'
      ),
    email: z.string().min(1, 'E-mail é obrigatório!').email('E-mail inválido!'),
    password: z.string().min(6, 'Informe uma senha!'),
    password_confirmation: z.string().min(6, 'Informe a senha novamente!'),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: 'Senhas não conferem!',
    path: ['password_confirmation'], // path of error
  });

export const CheckoutContactStep = ({
  maxProgress,
  progress,
  setProgress,
  formsState,
  setFormState,
}) => {
  const formRef = useRef(null);
  const formLoginRef = useRef(null);
  const [formErrors, setFormErrors] = useState({});
  const [formLoginErrors, setFormLoginErrors] = useState({});
  const [hasRegisteredCustomer, setHasRegisteredCustomer] = useState(false);
  const [showRegisterLoginForm, setShowRegisterLoginForm] = useState(false);
  const [registerFormLoading, setRegisterFormLoading] = useState(false);
  const { siteConfig } = useContext(SiteContext);
  const { authCustomer, setAuthCustomerToken } = useContext(AuthContext);
  // const navigate = useNavigate();
  const { auxColor, primaryColor, isDarkTheme } = useColors();
  const [phoneMask, setPhoneMask] = useState('(00) 0000-00000');
  const { ref: phoneRef } = useIMask(
    { mask: phoneMask },
    {
      onAccept: (value) => {
        setPhoneMask(value[5] === '9' && value.length >= 14 ? '(00) 00000-0000' : '(00) 0000-0000');
        onChangeHandler('telephoneQuickBuy', value);
      },
    }
  );
  const { ref: phoneRegisterRef } = useIMask(
    { mask: phoneMask },
    {
      onAccept: (value) => {
        setPhoneMask(value[5] === '9' && value.length >= 14 ? '(00) 00000-0000' : '(00) 0000-0000');
        onChangeHandler('telephone', value);
      },
    }
  );

  /**
   * Processa registro rápido para venda com dados de Customer
   * @param {*} e
   * @returns
   */
  const submitQuickBuyHandler = async (e) => {
    e.preventDefault();
    const values = Object.fromEntries(new FormData(formRef.current));
    const { success, error } = schemaQuickBuy.safeParse(values);

    if (!success) {
      const errors = error.format();
      setFormErrors({
        nameQuickBuy: errors.nameQuickBuy && errors.nameQuickBuy._errors[0],
        telephoneQuickBuy: errors.telephoneQuickBuy && errors.telephoneQuickBuy._errors[0],
        emailQuickBuy: errors.emailQuickBuy && errors.emailQuickBuy._errors[0],
      });
      return;
    }
    setFormState((state) => ({
      ...state,
      contact: {
        ...state.contact,
        name: values.nameQuickBuy,
        telephone: values.telephoneQuickBuy,
        email: values.emailQuickBuy,
      },
    }));
    setProgress(4);
  };

  /**
   * Verifica existência de Customer na Base
   * @param {*} e
   * @returns
   */
  const submitCheckEmailHandler = async (e) => {
    e.preventDefault();
    setRegisterFormLoading(true);

    const values = Object.fromEntries(new FormData(formLoginRef.current));
    const { success, error } = schemaCheckEmail.safeParse(values);

    if (!success) {
      const errors = error.format();
      setFormLoginErrors({
        email: errors.email && errors.email._errors[0],
      });
      setRegisterFormLoading(false);
      return;
    }

    const { data } = await checkIfCustomerExists(siteConfig.store.id, values.email);
    if (data.exists) {
      setShowRegisterLoginForm(false);
      setHasRegisteredCustomer(true);
    } else {
      setShowRegisterLoginForm(true);
      setHasRegisteredCustomer(false);
    }
    setRegisterFormLoading(false);
  };

  /**
   * Processa cadastro de Customer
   * @param {*} e
   * @returns
   */
  const submitRegisterHandler = async (e) => {
    e.preventDefault();
    setRegisterFormLoading(true);

    const values = Object.fromEntries(new FormData(formLoginRef.current));
    const { success, error } = schemaRegister.safeParse(values);

    if (!success) {
      const errors = error.format();
      setFormErrors({
        name: errors.name && errors.name._errors[0],
        telephone: errors.telephone && errors.telephone._errors[0],
        email: errors.email && errors.email._errors[0],
        password: errors.password && errors.password._errors[0],
        password_confirmation:
          errors.password_confirmation && errors.password_confirmation._errors[0],
      });
      setRegisterFormLoading(false);
      return;
    }
    //registra o Customer na base
    const { data } = await registerCustomer(
      siteConfig.store.id,
      values.name,
      values.email,
      values.telephone,
      values.password,
      values.password_confirmation
    );
    if (!data.success) {
      //Em caso de erro no cadastro do customer
      setRegisterFormLoading(false);
      return;
    }
    //Autentica o customer
    await processLogin(values.email, values.password);
    setFormState((state) => ({
      ...state,
      contact: {
        ...state.contact,
        name: values.name,
        telephone: values.telephone,
        email: values.email,
      },
    }));
    setProgress(4);
  };

  /**
   * Processa login de Customer com credenciais
   * @param {*} e
   * @returns
   */
  const submitLoginHandler = async (e) => {
    e.preventDefault();
    setRegisterFormLoading(true);

    const values = Object.fromEntries(new FormData(formLoginRef.current));
    const { success, error } = schemaLogin.safeParse(values);

    if (!success) {
      const errors = error.format();
      setFormLoginErrors({
        email: errors.email && errors.email._errors[0],
        password: errors.password && errors.password._errors[0],
      });
      setRegisterFormLoading(false);
      return;
    }
    //Processa o login do customer
    await processLogin(values.email, values.password);
  };

  const processLogin = async (email, password) => {
    const { data, message } = await processAuthCustomer(siteConfig.store.id, email, password);
    if (data && data?.success) {
      //autentica o customer e passa ele pra próxima tela
      setAuthCustomerToken(data.token);
      setFormState((state) => ({
        ...state,
        contact: {
          ...data.customer,
          name: data.customer.name,
          telephone: data.customer.telephone,
          email: email,
        },
      }));
      setProgress(4);
    } else {
      setFormLoginErrors({
        email: message,
      });
    }
    setRegisterFormLoading(false);
  };

  const onChangeHandler = (name, value) =>
    setFormState((state) => ({ ...state, contact: { ...state.contact, [name]: value } }));

  const getValueFromState = (name) => (formsState.contact ? formsState.contact[name] : '');

  return (
    <CheckoutDetails>
      <NavPills
        maxProgress={maxProgress}
        progress={progress}
        setProgress={setProgress}
        formsState={formsState}
      />
      <CheckoutContactContainer>
        {!authCustomer && (
          <CheckoutContactForm ref={formRef}>
            <CheckoutFormTitle primaryColor={primaryColor}>Compra Rápida</CheckoutFormTitle>
            <CheckoutFormSpan>
              Processaremos o seu pedido, mas você não poderá acompanhá-lo.
            </CheckoutFormSpan>
            <FormRow>
              <FormCol width="100%">
                <FormLabel htmlFor="nameQuickBuy">Nome</FormLabel>
                <FormInput
                  type="text"
                  name="nameQuickBuy"
                  id="nameQuickBuy"
                  placeholder="Nome"
                  value={getValueFromState('nameQuickBuy')}
                  onChange={(e) => onChangeHandler('nameQuickBuy', e.target.value)}
                />
                {formErrors.nameQuickBuy && (
                  <FormErrorSpan>{formErrors.nameQuickBuy}</FormErrorSpan>
                )}
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol width="35%">
                <FormLabel htmlFor="telephoneQuickBuy">Telefone</FormLabel>
                <FormInput
                  ref={phoneRef}
                  type="tel"
                  name="telephoneQuickBuy"
                  id="telephoneQuickBuy"
                  placeholder="Telefone"
                  value={getValueFromState('telephoneQuickBuy')}
                />
                {formErrors.telephoneQuickBuy && (
                  <FormErrorSpan>{formErrors.telephoneQuickBuy}</FormErrorSpan>
                )}
              </FormCol>
              <FormCol width="65%">
                <FormLabel htmlFor="emailQuickBuy">Email</FormLabel>
                <FormInput
                  type="email"
                  name="emailQuickBuy"
                  id="emailQuickBuy"
                  placeholder="Email"
                  value={getValueFromState('emailQuickBuy')}
                  onChange={(e) => onChangeHandler('emailQuickBuy', e.target.value)}
                />
                {formErrors.emailQuickBuy && (
                  <FormErrorSpan>{formErrors.emailQuickBuy}</FormErrorSpan>
                )}
              </FormCol>
            </FormRow>
            <FormRow>
              <FormCol width="40%">
                <ButtonRounded
                  onClick={submitQuickBuyHandler}
                  auxColor={auxColor}
                  primaryColor={primaryColor}
                  isDarkTheme={isDarkTheme}
                >
                  Confirmar
                </ButtonRounded>
              </FormCol>
            </FormRow>
          </CheckoutContactForm>
        )}
        {!authCustomer && (
          <CheckoutContactLogin ref={formLoginRef}>
            <CheckoutFormTitle primaryColor={primaryColor}>
              Entrar com sua Conta / Cadastre-se
            </CheckoutFormTitle>
            {!showRegisterLoginForm ? (
              <CheckoutFormSpan>Acompanhe seu pedido e compras futuras.</CheckoutFormSpan>
            ) : (
              <CheckoutFormSpan>Cadastre-se para entrar</CheckoutFormSpan>
            )}
            {registerFormLoading && (
              <FormRow>
                <FormCol
                  width="100%"
                  style={{ alignItems: 'center', marginRight: '20%', marginTop: '10%' }}
                >
                  <LoadingSpinner />
                </FormCol>
              </FormRow>
            )}
            {!registerFormLoading && (
              <>
                <FormRow>
                  <FormCol width="100%">
                    <FormLabel htmlFor="email">E-mail</FormLabel>
                    <FormInput
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Informe seu e-mail"
                      value={getValueFromState('email')}
                      onChange={(e) => onChangeHandler('email', e.target.value)}
                    />
                    {formLoginErrors.email && (
                      <FormErrorSpan>{formLoginErrors.email}</FormErrorSpan>
                    )}
                  </FormCol>
                </FormRow>
                {hasRegisteredCustomer && (
                  <FormRow>
                    <FormCol width="100%">
                      <FormLabel htmlFor="email">Senha</FormLabel>
                      <FormInput
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Senha"
                        value={getValueFromState('password')}
                        onChange={(e) => onChangeHandler('password', e.target.value)}
                      />
                      {formLoginErrors.password && (
                        <FormErrorSpan>{formLoginErrors.password}</FormErrorSpan>
                      )}
                    </FormCol>
                  </FormRow>
                )}
                {!hasRegisteredCustomer && showRegisterLoginForm && (
                  <>
                    <FormRow>
                      <FormCol width="100%">
                        <FormLabel htmlFor="name">Nome</FormLabel>
                        <FormInput
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Preencha seu nome"
                          value={getValueFromState('name')}
                          onChange={(e) => onChangeHandler('name', e.target.value)}
                        />
                        {formLoginErrors.name && (
                          <FormErrorSpan>{formLoginErrors.name}</FormErrorSpan>
                        )}
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol width="100%">
                        <FormLabel htmlFor="telephone">Telefone</FormLabel>
                        <FormInput
                          ref={phoneRegisterRef}
                          type="tel"
                          name="telephone"
                          id="telephone"
                          placeholder="Telefone"
                        />
                        {formLoginErrors.telephone && (
                          <FormErrorSpan>{formLoginErrors.telephone}</FormErrorSpan>
                        )}
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol width="100%">
                        <FormLabel htmlFor="email">Senha</FormLabel>
                        <FormInput
                          type="password"
                          name="password"
                          id="password"
                          placeholder="Senha"
                          value={getValueFromState('password')}
                          onChange={(e) => onChangeHandler('password', e.target.value)}
                        />
                        {formLoginErrors.password && (
                          <FormErrorSpan>{formLoginErrors.password}</FormErrorSpan>
                        )}
                      </FormCol>
                    </FormRow>
                    <FormRow>
                      <FormCol width="100%">
                        <FormLabel htmlFor="email">Confirmar Senha</FormLabel>
                        <FormInput
                          type="password"
                          name="password_confirmation"
                          id="password_confirmation"
                          placeholder="Confirme sua Senha"
                          value={getValueFromState('password_confirmation')}
                          onChange={(e) => onChangeHandler('password_confirmation', e.target.value)}
                        />
                        {formLoginErrors.password_confirmation && (
                          <FormErrorSpan>{formLoginErrors.password_confirmation}</FormErrorSpan>
                        )}
                      </FormCol>
                    </FormRow>
                  </>
                )}
                {!showRegisterLoginForm && !hasRegisteredCustomer && (
                  <FormRow>
                    <FormCol width="40%">
                      <ButtonRounded
                        onClick={submitCheckEmailHandler}
                        auxColor={auxColor}
                        primaryColor={primaryColor}
                        isDarkTheme={isDarkTheme}
                      >
                        Avançar
                      </ButtonRounded>
                    </FormCol>
                  </FormRow>
                )}
                {hasRegisteredCustomer && (
                  <FormRow>
                    <FormCol width="40%">
                      <ButtonRounded
                        onClick={submitLoginHandler}
                        auxColor={auxColor}
                        primaryColor={primaryColor}
                        isDarkTheme={isDarkTheme}
                      >
                        Entrar
                      </ButtonRounded>
                    </FormCol>
                  </FormRow>
                )}
                {showRegisterLoginForm && !hasRegisteredCustomer && (
                  <FormRow>
                    <FormCol width="50%">
                      <ButtonRounded
                        onClick={submitRegisterHandler}
                        auxColor={auxColor}
                        primaryColor={primaryColor}
                        isDarkTheme={isDarkTheme}
                      >
                        Cadastre-se
                      </ButtonRounded>
                    </FormCol>
                  </FormRow>
                )}
              </>
            )}
          </CheckoutContactLogin>
        )}
        {authCustomer && (
          <CheckoutContactAuth>
            <h3>Olá, {authCustomer.name}!</h3>
            <span>Continue a compra com as suas informações:</span>
            <h4>{authCustomer.email}</h4>
            <h4>{authCustomer.telephone}</h4>
            <FormRow>
              <FormCol width="50%">
                <ButtonRounded
                  onClick={() => setProgress(2)}
                  auxColor={auxColor}
                  primaryColor={primaryColor}
                  isDarkTheme={isDarkTheme}
                >
                  Voltar
                </ButtonRounded>
              </FormCol>
              <FormCol width="50%">
                <ButtonRounded
                  onClick={() => setProgress(4)}
                  auxColor={auxColor}
                  primaryColor={primaryColor}
                  isDarkTheme={isDarkTheme}
                >
                  Confirmar
                </ButtonRounded>
              </FormCol>
            </FormRow>
          </CheckoutContactAuth>
        )}
      </CheckoutContactContainer>
      {/* {!authCustomer && (
        <FormRow style={{ justifyContent: 'start' }}>
          <FormCol width="100%">
            <ButtonRounded
              onClick={() => setProgress(2)}
              auxColor={auxColor}
              primaryColor={primaryColor}
              isDarkTheme={isDarkTheme}
            >
              Voltar
            </ButtonRounded>
          </FormCol>
        </FormRow>
      )} */}
    </CheckoutDetails>
  );
};
