import styled from 'styled-components';
import LoadingSpinner from '../Loading/LoadingSpinner';

export const CenteredLoadingSpinner = styled(LoadingSpinner)`
  margin: 5rem auto;
`;

export const ProductListWrapper = styled.div`
  display: grid;
  gap: 1rem;

  grid-template-columns: repeat(4, 1fr);

  @media (max-width: 725px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }

  padding: 1.5rem calc(10% - 1rem) 2rem;
`;

export const P = styled.p`
  font-size: 0.6875rem;
  max-width: 90%;
  display: block;
  text-align: center;
  margin: 4rem auto 3rem;
  color: #a9b1be;
`;
