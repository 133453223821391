import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoadingSpinner = styled.p`
  width: ${(props) => props.size || '3.5rem'};
  height: ${(props) => props.size || '3.5rem'};
  border: 0.2rem solid ${(props) => props.color || '#222'};
  border-right-color: transparent;
  border-radius: 50%;
  animation: ${rotate} 0.75s linear infinite;
`;

export default LoadingSpinner;
