import { useState, useContext, useEffect } from 'react';
import useColors from '../../hooks/useColors';
import { ConfirmStep } from '../../components/Cart/ConfirmStep';
import { CheckoutSummary } from '../../components/Cart/Summary';
import { CheckoutContactStep } from '../../components/Cart/ContactForm';
import { CheckoutAddressStep } from '../../components/Cart/AddressForm';
import { CheckoutPaymentStep } from '../../components/Cart/PaymentForm';
import { FinishStep } from '../../components/Cart/FinishStep';
import { CheckoutCard } from './styles';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import AuthContext from '../../contexts/AuthContext';
import CheckoutContext from '../../contexts/CheckoutContext';

const CartPage = () => {
  const { primaryColor } = useColors();
  const [maxProgress, setMaxProgress] = useState(1);
  const [progress, setCurrentProgress] = useState(1);
  const { checkIfProductsStillExists } = useContext(CheckoutContext);
  const [orderData, setOrderData] = useState([]);
  const { authCustomer } = useContext(AuthContext);
  const setProgress = (p) => {
    setMaxProgress((state) => (p > state ? p : state));
    setCurrentProgress(p);
  };

  const [formsState, setFormState] = useState({});

  useEffect(() => {
    if (authCustomer) {
      setFormState((state) => ({
        ...state,
        contact: {
          ...state.contact,
          name: authCustomer.name,
          telephone: authCustomer.telephone,
          email: authCustomer.email,
        },
      }));
    }
  }, [authCustomer]);

  useEffect(() => {
    checkIfProductsStillExists();
  }, [progress, checkIfProductsStillExists]);

  return (
    <>
      <Header />
      <CheckoutCard progress={progress} primaryColor={primaryColor}>
        {progress === 1 && (
          <CheckoutSummary
            maxProgress={maxProgress}
            progress={progress}
            setProgress={setProgress}
          />
        )}
        {progress === 2 && (
          <CheckoutPaymentStep
            maxProgress={maxProgress}
            progress={progress}
            formsState={formsState}
            setFormState={setFormState}
            setProgress={setProgress}
          />
        )}
        {progress === 3 && (
          <CheckoutContactStep
            maxProgress={maxProgress}
            progress={progress}
            formsState={formsState}
            setFormState={setFormState}
            setProgress={setProgress}
          />
        )}
        {progress === 4 && (
          <CheckoutAddressStep
            maxProgress={maxProgress}
            progress={progress}
            formsState={formsState}
            setFormState={setFormState}
            setProgress={setProgress}
          />
        )}
        {progress === 5 && (
          <ConfirmStep
            maxProgress={maxProgress}
            progress={progress}
            setProgress={setProgress}
            formsState={formsState}
            setOrderData={setOrderData}
          />
        )}
        {progress === 6 && <FinishStep formsState={formsState} orderData={orderData} />}
      </CheckoutCard>
      <Footer />
    </>
  );
};

export default CartPage;
