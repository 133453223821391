import { useContext } from 'react';
import { RouterProvider } from 'react-router-dom';

import Error from './pages/Error';
import Loading from './pages/Loading';
import SiteContext from './contexts/SiteConfigContext';

import router from './routes';

const App = () => {
  const { loadingSiteState, subdomain } = useContext(SiteContext);

  if (loadingSiteState) return <Loading />;

  if (subdomain === false) return <Error />;

  return <RouterProvider router={router} />;
};

export default App;
