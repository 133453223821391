import api from '../api';

export async function siteRead(slug) {
  try {
    const { data } = await api.get('store/' + slug, {});
    return {
      message: 'Dados do site carregados com sucesso.',
      success: true,
      data,
    };
  } catch (error) {
    return {
      message: 'Erro ao carregar dados do site. Tente mais tarde.',
      success: false,
    };
  }
}
