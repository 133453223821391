import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Modal from 'styled-react-modal';

export const ButtonRounded = styled.button`
  width: 100%;
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.7rem;
  border: none;
  cursor: pointer;
  transition: box-shadow 0.3s ease;

  background-color: ${(props) => props.primaryColor};
  color: ${(props) => props.auxColor};
  border: ${(props) => (!props.isDarkTheme ? '1px solid black' : 'none')};

  margin: 1rem 0 0 auto;
  @media (min-width: 768px) {
    margin: 1.2rem 0 0 auto;
  }

  &:hover {
    box-shadow: 1px 2px 2px #aaaaaa88;
  }
`;

export const ProcessedByAdvice = styled.p`
  margin-left: auto;
  margin-top: -0.5rem;
  font-size: 0.7rem;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const ConfirmationStep = styled.div`
  padding: 1.5rem 0;

  .confirmation-columns {
    display: flex;
    gap: 1.5rem;
    flex-direction: column;

    & > div {
      width: 100%;
    }
  }

  @media (min-width: 768px) {
    /* padding: 1.5rem 15rem; */

    .confirmation-columns {
      flex-direction: row;
    }
  }

  ul.list-products {
    list-style: none;

    li {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1.5rem;

      .product-card {
        background-color: white;
        border: 1px solid #f0f0f0;
        border-radius: 1rem;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        width: 100%;

        div > span {
          position: absolute;
          right: -0.5rem;
          top: -0.4rem;
          background-color: ${(props) => props.primaryColor};
          color: ${(props) => props.auxColor};
          font-size: 0.9rem;
          text-align: center;
          height: 1.5rem;
          width: 1.5rem;
          border-radius: 99999px;
        }
      }

      .product-image {
        border: none;
        aspect-ratio: 1;
        width: 4rem;
        height: 4rem;
        @media (max-width: 768px) {
          width: 30cqw;
          height: 30cqw;
        }
        border-radius: 5px;
        font-size: 1.5rem;
      }

      div.product-image {
        padding: 1rem;
        background-color: ${(props) => props.primaryColor};
        color: #faab2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .confirmation-sections > div {
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid #ddd;

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
      }

      h1 {
        display: flex;
        justify-content: space-between;
        font-size: 1rem;
        color: ${(props) => props.primaryColor};
        margin-bottom: 0.25rem;

        .edit-button {
          margin-left: auto;
          border: none;
          background: none;
          font-size: 0.9rem;
          cursor: pointer;
          img {
            margin-bottom: -0.25rem;
          }
        }
      }
    }
  }
`;

export const SaleContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 1px 0px 20px #ccc;
  padding-bottom: 40px;
`;

export const SaleContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
`;

export const SaleContentTitle = styled.h1`
  font-size: 24px;
  color: ${({ primaryColor, auxColor, isDarkTheme }) => (isDarkTheme ? primaryColor : auxColor)};
`;

export const SaleContentOrderNumber = styled.span`
  font-size: 18px;
  color: #333;
`;

export const SaleContactButton = styled.button`
  padding: 10px 20px;
  background: ${(props) => props.primaryColor};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

export const SaleOrderDetails = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  background-color: #fff;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const SaleItemList = styled.div`
  border-bottom: 1px solid #ccc;
  flex: 1;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

export const SaleItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .product-image {
    border: none;
    aspect-ratio: 1;
    width: 60px;
    height: 60px;
    @media (max-width: 768px) {
      width: 60px;
      height: 60px;
    }
    border-radius: 5px;
    font-size: 1.5rem;
  }

  div.product-image {
    padding: 1.2rem;
    background-color: ${(props) => props.primaryColor};
    color: #faab2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    text-align: center;
    margin-right: 10px;
  }
`;

export const SaleItemImage = styled.div`
  width: 60px;
  height: 60px;
  background: #e0e0e0;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;

export const SaleItemInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SaleItemName = styled.span`
  font-size: 16px;
  color: #333;
`;

export const SaleItemPrice = styled.span`
  font-size: 14px;
  color: #999;
`;

export const SaleSummary = styled.div`
  flex: 2;
  padding: 20px;
  border-right: 1px solid #e0e0e0;

  @media (max-width: 768px) {
    border-right: 0px;
  }
`;

export const SaleSummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const SaleSummaryLabel = styled.span`
  font-size: 14px;
  color: #999;
`;

export const SaleSummaryValue = styled.span`
  font-size: 14px;
  color: #333;
`;

export const SaleTotal = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  color: #333;
`;

export const SaleDetailsSection = styled.div`
  flex: 1;
  padding: 20px;
`;

export const SaleDetailsRow = styled.div`
  margin-bottom: 10px;
  word-wrap: break-word;
`;

export const SaleDetailsLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${({ primaryColor, auxColor, isDarkTheme }) => (isDarkTheme ? primaryColor : auxColor)};
  margin-bottom: 5px;
`;

export const SaleDetailsValue = styled.span`
  font-size: 14px;
  color: #333;
`;

export const PaymentDescription = styled.p`
  font-size: 12px;
`;
