import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import useColors from '../../hooks/useColors';
import Helpers from '../../utils/helpers';

import { ProductListItemWrapper, PromotionalPriceView, PromotionalTag } from './styles';
import SiteConfigContext from '../../contexts/SiteConfigContext';

const ProductListItem = ({ product }) => {
  const { primaryColor, isDarkTheme, auxColor } = useColors();
  const { siteConfig } = useContext(SiteConfigContext);

  return (
    <ProductListItemWrapper
      auxColor={auxColor}
      isDarkTheme={isDarkTheme}
      primaryColor={primaryColor}
      siteConfig={siteConfig.store.configs}
    >
      <NavLink to={'/produtos/' + product.id}>
        <>
          {product.promotional_price && (
            <PromotionalTag>
              {Helpers.promotionalPercentage(product.sell_price, product.promotional_price)}% OFF
            </PromotionalTag>
          )}
          {product.image ? (
            <img src={product.image} alt="" />
          ) : (
            <div className="image-placeholder">
              <i className="fa-solid fa-camera"></i>
            </div>
          )}
        </>

        <h2>{product.name}</h2>

        <h3>{product.category?.name}</h3>

        {product.promotional_price ? (
          <PromotionalPriceView>
            <span>{Helpers.numberToReal(product.sell_price)}</span>
            <h4>{Helpers.numberToReal(product.promotional_price)}</h4>
          </PromotionalPriceView>
        ) : (
          <h4>{Helpers.numberToReal(product.sell_price)}</h4>
        )}

        {product.amount < 1 && <h3 className="text-error">Estoque indisponível</h3>}
      </NavLink>
    </ProductListItemWrapper>
  );
};

export default ProductListItem;
