import styled from 'styled-components';

export const BannerWrapper = styled.div`
  background-color: #f8f8f8;
  padding: 0 9%;

  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }

  .swiper {
    border-radius: 10px;
    overflow: hidden;
    height: 40vh;
  }

  .swiper-pagination-bullet-active {
    background-color: #787878dd;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 1.5rem;
    color: #efefef;
  }

  .swiper-button-next {
    right: 3rem;
  }

  .swiper-button-prev {
    left: 3rem;
  }

  @media (max-width: 576px) {
    .swiper-button-next {
      right: 1.5rem;
    }

    .swiper-button-prev {
      left: 1.5rem;
    }
  }
`;
