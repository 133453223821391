import { CustomerHeaderContainer, CustomerHeaderLink } from './styles';

const CustomerHeaderComponent = ({ page, primaryColor }) => {
  return (
    <CustomerHeaderContainer>
      <CustomerHeaderLink color={primaryColor} selected={page === 'sales'} to="/meus-pedidos">
        Meus Pedidos
      </CustomerHeaderLink>
      <CustomerHeaderLink color={primaryColor} selected={page === 'profile'} to="/meu-perfil">
        Editar Perfil
      </CustomerHeaderLink>
    </CustomerHeaderContainer>
  );
};

export default CustomerHeaderComponent;
