import { useContext, useState } from 'react';
import ProductSearchContext from '../../contexts/ProductsSearchContext';
import useColors from '../../hooks/useColors';
import { SearchInput } from './styles';

const SearchInputComponent = () => {
  const { query, setQuery, setPage } = useContext(ProductSearchContext);
  const { primaryColor } = useColors();

  const [debounce, setDebounce] = useState(null);

  const onChangeHandler = ({ target }) => {
    if (debounce) clearTimeout(debounce);

    setDebounce(
      setTimeout(() => {
        setPage(1);
        setQuery(target.value);
      }, 500)
    );
  };

  return (
    <SearchInput
      type="search"
      placeholder="Buscar produto..."
      primaryColor={primaryColor}
      defaultValue={query}
      onChange={onChangeHandler}
    />
  );
};

export default SearchInputComponent;
