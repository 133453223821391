import React, { useContext } from 'react';
import SiteContext from '../../contexts/SiteConfigContext';
import useColors from '../../hooks/useColors';
import { IconList } from './IconList';
import { Column, Footer, P } from './styles';

function FooterComponent() {
  const { siteConfig } = useContext(SiteContext);

  const { primaryColor, auxColor } = useColors();

  return (
    <Footer primaryColor={primaryColor}>
      <P auxColor={auxColor}>
        © {new Date().getFullYear()} {siteConfig?.store?.name} - todos os direitos reservados
      </P>
      <Column>
        <IconList siteConfig={siteConfig} auxColor={auxColor} />
        <P auxColor={auxColor}>{siteConfig?.store?.configs?.extra_info}</P>
      </Column>
    </Footer>
  );
}

export default FooterComponent;
